@charset "utf-8";

@import "~select2/dist/css/select2.min.css";

.select2-container--default .select2-selection--single {
	border: 1px solid #e4e4e4;
}

.select2-container {
	width: 70px !important;
}

.select2-search--dropdown {
	display: none !important;
}

.select2-container--focus, .select2-selection:focus {
	outline: none;
	box-shadow: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #f55b5b transparent transparent transparent;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #f55b5b transparent;
}

.select2-dropdown {
	border: 1px solid #e4e4e4;
}

.select2-container--default .select2-results__option[aria-selected=true] {
	background-color: #e4e4e4;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #f55b5b;
}
