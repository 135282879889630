@charset "utf-8";

@font-face {
	font-family: "SFUIDisplay-Regular";
	src: url("./../fonts/SFUIDisplay-Regular.woff") format("woff"),
		url("./../fonts/SFUIDisplay-Regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "SFUIDisplay-Medium";
	src: url("./../fonts/SFUIDisplay-Medium.woff") format("woff"),
		url("./../fonts/SFUIDisplay-Medium.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "SFUIDisplay-Semibold";
	src: url("./../fonts/SFUIDisplay-Semibold.woff") format("woff"),
		url("./../fonts/SFUIDisplay-Semibold.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "SFUIDisplay-Bold";
	src: url("./../fonts/SFUIDisplay-Bold.woff") format("woff"),
		url("./../fonts/SFUIDisplay-Bold.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "ArnoPro-Smbd";
	src: url("./../fonts/ArnoPro-Smbd.woff") format("woff"),
		url("./../fonts/ArnoPro-Smbd.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

html, body {
	height: 100%;
}

body {
	position: relative;
	margin: 0;
	padding: 0;
	font-family: "SFUIDisplay-Regular", Arial, Helvetica, sans-serif;
	line-height: 1.3;
	font-size: 15px;
	color: #323232;
	background: #f1f7f9;
}

h2, h3, h4, h5 {
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	letter-spacing: normal;
	color: #000;
	margin: 0 0 20px 0;
	line-height: 1.3;
}

h1 {
	font-family: "ArnoPro-Smbd", "Times New Roman", Times, serif;
	color: #000;
	font-size: 30px;
	margin: 0 0 15px 0;
	line-height: 1.1;
}

h2 {
	font-size: 38px;
}

h3 {
	font-size: 34px;
}

h4 {
	font-size: 24px;
	margin: 10px 0 10px 0;
}

h5 {
	font-size: 20px;
	margin: 0 0 10px 0;
}

h6 {
	font-size: 18px;
	margin: 0 0 10px 0;
	text-transform: none;
}

p+h2, div+h2 {
	margin-top: 45px;
}

p+h3, h2+h3, h3+h4 {
	margin-top: 35px;
}
p {
	margin: 0 0 20px 0;
}

a {
	color: #f55b5b;
	text-decoration: underline;
	&:hover {
		color: #f55b5b;
		text-decoration: none;
	}
	&:focus {
		color: #f55b5b;
		outline: none;
	}
}

b,
strong {
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-weight: normal;
}

fieldset {
	padding: .35em .625em .75em;
	margin: 0 2px;
	border: 1px solid #c0c0c0;
}

button {
	display: block;
	&:focus {
		border: none;
		outline: none;
	}
}

input[type="submit"] {
	&:focus {
		border: none;
		outline: none;
	}
}

input[type="file"] {
	background: transparent;
	border: none;
}

select,
textarea,
textarea.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input,
.form-control {
	border: none;
	font-size: 13px;
	color: #000;
	padding: 12px 20px;
	line-height: 1.5;
	outline: none;
	border-radius: 4px;
	box-shadow: none;
	background-color: #f1f7f9;
	height: inherit;
	font-family: "SFUIDisplay-Regular", Arial, Helvetica, sans-serif;
	vertical-align: middle;
}

select:focus,
textarea:focus,
textarea.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus,
.placeholder,
.form-control:focus {
	border-radius: 4px;
	color: #000;
	-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	-moz-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	background-color: #ebf2f6;
}

label {
	font-weight: normal;
	&.error {
		margin: 0;
		color: #f00;
		font-size: 15px;
	}
}

img {
	max-width: 100%;
}

.checkbox,
.radio {
	margin: 0;
	position: relative;
	label {
		margin: 0;
		font-weight: normal;
		padding-left: 35px;
		position: relative;
		cursor: pointer;
	}
}

.checkbox {
	input[type=checkbox] {
		top: 0;
		position: absolute;
		opacity: 0;
		clip: rect(0, 0, 0, 0);
		&:checked {
			+label {
				&:after,
				&:hover:after {
					background: url("./../svg/icon-checkbox-checked.svg") no-repeat;
				}
			}
		}
	}
	label {
		line-height: 26px;
		&:after {
			content: " ";
			display: inline-block;
			position: absolute;
			width: 26px;
			height: 26px;
			background: url("./../svg/icon-checkbox.svg") no-repeat;
			left: 0;
			margin-right: 14px;
		}
	}
}

.radio {
	input[type=radio] {
		top: 2px;
		position: absolute;
		opacity: 0;
		clip: rect(0, 0, 0, 0);
		&:checked {
			+label {
				&:after,
				&:hover:after {
					background: url("./../svg/icon-radio-checked.svg") no-repeat;
				}
			}
		}
	}
	label {
		&:after {
			content: " ";
			display: inline-block;
			position: absolute;
			width: 26px;
			height: 26px;
			background: url("./../svg/icon-radio.svg") no-repeat;
			left: 0;
			top: 3px;
			margin-right: 14px;
		}
	}
}

.radio + .radio,
.checkbox + .checkbox {
	margin-top: 0;
}

.form-group {
	margin-bottom: 25px;
}

.form-control {
	border: none;
	font-size: 13px;
	color: #000;
	padding: 12px 20px;
	line-height: 1.5;
	outline: none;
	border-radius: 4px;
	box-shadow: none;
	background-color: #f1f7f9;
	height: inherit;
	font-family: "SFUIDisplay-Regular", Arial, Helvetica, sans-serif;
	vertical-align: middle;
}

.form-control:focus {
	border-radius: 4px;
	color: #000;
	-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	-moz-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	outline: none;
	border: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	background-color: #ebf2f6;
}

.form-control:-moz-placeholder {
	color: #9c9c9c;
	font-size: 13px;
	font-family: "SFUIDisplay-Regular", Arial, Helvetica, sans-serif;
}

.form-control::-o-placeholder {
	color: #9c9c9c;
	font-size: 13px;
	font-family: "SFUIDisplay-Regular", Arial, Helvetica, sans-serif;
}

.form-control:-ms-input-placeholder {
	color: #9c9c9c;
	font-size: 13px;
	font-family: "SFUIDisplay-Regular", Arial, Helvetica, sans-serif;
}

.form-control::-webkit-input-placeholder {
	color: #9c9c9c;
	font-size: 13px;
	font-family: "SFUIDisplay-Regular", Arial, Helvetica, sans-serif;
}

textarea.form-control,
textarea.form-control:focus {
	height: inherit;
}

.form-horizontal .control-label {
	padding-top: 3px;
}

.btn {
	background: #f55b5b;
	padding: 12px 30px;
	font-size: 16px;
	color: #fff;
	text-decoration: none;
	border-radius: 4px;
	display: inline-block;
	cursor: pointer;
	-moz-transition: all ease-out 0.2s;
	-webkit-transition: all ease-out 0.2s;
	-o-transition: all ease-out 0.2s;
	transition: all ease-out 0.2s;
	border: none;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	position: relative;
	vertical-align: middle;
}

.btn:hover,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
	outline: none;
	border: none;
	outline-offset: 0;
	box-shadow: none;
}

.btn:focus {
	outline: hidden;
	border: none;
	outline-offset: none;
}

.btn:hover,
.btn:focus,
.btn:active:focus,
.btn:active:hover {
	background: #d44343;
	border: none;
	box-shadow: none;
	color: #fff;
	text-decoration: none;
}

.btn:active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled):active:focus {
	background-image: none;
	border: none;
	outline: hidden;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-o-box-shadow: none;
	box-shadow: none;
}

.btn-round {
	border-radius: 25px;
}

.btn-primary {
	background-color: #88c328;
	box-shadow: none;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled):active:focus {
	background: #7db523;
}

.btn-default {
	background-color: transparent;
	border: #dbdbdb solid 1px;
	box-shadow: none;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active:focus,
.btn-default:not(:disabled):not(.disabled):active,
.btn-default:not(:disabled):not(.disabled):active:focus {
	background: #f5f5f5;
	border: #f5f5f5 solid 1px;
}

.btn-success {
	background-color: #ff4c01;
	border: #ff4c01 solid 1px;
	color: #fff;
	box-shadow: none;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active:focus,
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled):active:focus {
	background: #e34604;
	color: #fff;
	box-shadow: none;
	border: #e34604 solid 1px;
}

.btn + .btn {
	margin-left: 10px;
}

.push {
	height: 253px;
}

.wrap {
	min-height: 100%;
	height: auto !important;
	height: 100%;
	margin: 0 auto -253px;
}

.navbar-toggle {
	position: relative;
	margin: 0 auto 15px auto;
	border: none;
}

.navbar-toggle .icon-bar {
	background-color: #18538e;
}

.navbar-collapse {
	border-top: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-o-box-shadow: none;
	box-shadow: none;
}

.col-form-label {
	padding-top: calc(.975rem + 1px);
	padding-bottom: inherit;
}

.modal {
	overflow-y: auto;
}

.modal-open {
	overflow: auto;
}

.bx-wrapper {
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: none;
	background: transparent;
	position: relative;
	margin: 0 auto;
	padding: 0;
}

.fancybox-close {
	background-color: transparent;
	border: none;
	background-position: -118px -368px;
	width: 46px;
	height: 46px;
	position: absolute;
	top: -26px;
	right: -26px;
}

.fancybox-nav span {
	width: 45px;
	height: 45px;
	margin-top: -22px;
}

.fancybox-prev span {
	background: url("./../images/fancybox.png") -95px -45px;
}

.fancybox-prev span:hover {
	background: url("./../images/fancybox.png") -95px -45px;
}

.fancybox-next span {
	background: url("./../images/fancybox.png") -140px -45px;
}

.fancybox-next span:hover {
	background: url("./../images/fancybox.png") -140px -45px;
}

input[type=radio], input[type=checkbox] {
	margin-top: 7px;
}

.radio,
.checkbox {
	margin-bottom: 7px;
}

.container {
	position: relative;
}

.more-large {
	text-align: center;
	margin: 20px 0 0 0;
	a {
		display: inline-block;
		font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
		font-size: 15px;
		color: #fff;
		text-transform: uppercase;
		padding: 15px 35px;
		text-decoration: none;
		background: #f55b5b;
		border-radius: 4px;

		&:hover,
		&:focus {
			background: #d44343;
		}
	}
}

.breadcrumb {
	padding: 0;
	display: block;
	margin: 0 0 20px 0;
	background-color: #fff;
	border-radius: 4px;
	font-size: 14px;
	list-style: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
	color: #000;
	line-height: 46px;
	.breadcrumb-item {
		display: inline-block;
		margin: 0;
		padding: 0;
		vertical-align: top;
		position: relative;
		&.active {
			color: #f00;
		}
		+ .breadcrumb-item {
			&:before {
				display: none;
			}
			&:after {
				width: 15px;
				height: 46px;
				margin: 0;
				content: "";
				background: url("./../svg/icon-breadcrumb-arrow.svg");
				display: inline-block;
				vertical-align: top;
			}
		}

		> a,
		> span {
			text-decoration: none;
			display: inline-block;
			margin: 0;
			padding: 0 20px;
			position: relative;
			color: #000;
		}

		a {
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}

		&:first-child {
			a {
				padding: 0;
				width: 46px;
				display: block;
				height: 46px;
				text-indent: 100%;
				white-space: nowrap;
				overflow: hidden;
				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 16px;
					left: 15px;
					width: 16px;
					height: 14px;
					background: url("./../svg/icon-breadcrumbs-home.svg") no-repeat;
				}
			}

			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				width: 1px;
				height: 46px;
				background: #eaeaea;
			}
		}
	}
}

.pagination {
	margin-top: 30px;
	text-align: center;
	border-radius: 4px;
}

.page-item:first-child .page-link {
	border-radius: 4px 0 0 4px;
}

.page-item:last-child .page-link {
	border-radius: 0 4px 4px 0;
}

.page-link {
	position: relative;
	display: block;
	padding: 10px 15px;
	margin-left: -1px;
	font-size: 15px;
	color: #000;
	background-color: #fff;
	text-decoration: none;
	border: 1px solid #dee2e6;
	font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
}

.page-link:hover, .page-linkfocus {
	color: #000;
	background-color: #e9ecef;
	border-color: #dee2e6;
}

.page-link:focus {
	box-shadow: none;
}

.page-item.active .page-link,
.page-item.active .page-link:hover,
.page-item.active .page-link:focus,
span.page-link {
	background-color: #f55b5b;
	color: #fff;
	border-color: #f55b5b;
}

.page-list {
	margin: 0 0 25px 20px;
	padding: 0;
	list-style: none;
}

.page-list li {
	margin-bottom: 10px;
	padding-left: 25px;
	position: relative;
}

.page-list li::before {
	content: "—";
	position: absolute;
	top: 0;
	left: 0;
}

.login__form_popup,
.call__form_popup,
.report__form_popup,
.oneclick__form_popup,
.optioned__form_popup {
	.fancybox-skin {
		background: #fff;
		box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
		border-radius: 4px;
		padding: 35px;
	}
	.fancybox-close {
		background: url("./../svg/icon-modal-close.svg") no-repeat;
	}
	h3 {
		text-align: center;
		font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
		color: #090909;
		font-size: 14px;
		text-transform: uppercase;
		margin: 0 0 25px 0;
	}

	.form-group {
		margin-bottom: 15px;
	}

	.form-control {
		text-align: center;
	}

	.btn {
		min-width: 170px;
	}
}

.image__gallery_popup {
	.fancybox-close {
		background: url("./../svg/icon-modal-close.svg") no-repeat;
	}

	.fancybox-nav span {
		width: 27px;
		height: 27px;
		margin-top: -22px;
	}

	.fancybox-prev span {
		&:hover,
		& {
			background: url("./../svg/icon-modal-prev.svg");
		}
	}

	.fancybox-next span {
		&:hover,
		& {
			background: url("./../svg/icon-modal-next.svg");
		}
	}
}

.form-container {
	margin: 0;
	max-width: 320px;
}

.forgot-password {
	color: #a4a4a4;
	font-size: 13px;
}

.text-small {
	font-size: 12px;
}

.hide__mobile {
	display: none;
}

.navbar-collapse.collapse {
	display: none !important;
}

.navbar-expand-lg {
	display: none;
}

.header-top {
	height: 40px;
	background: #977eac;
}

.header-top__btn {
	float: left;
	margin: 5px 0 0 300px;
}

.header-btn {
	border: #ac98bd solid 1px;
	margin: 0 3px;
	padding: 5px 25px;
	border-radius: 15px;
	-moz-transition: all ease-out 0.2s;
	-webkit-transition: all ease-out 0.2s;
	-o-transition: all ease-out 0.2s;
	transition: all ease-out 0.2s;
	color: #fff;
	display: inline-block;
	text-decoration: none;
	font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
	font-size: 14px;
}

.header-btn:hover,
.header-btn:focus {
	background-color: #ac98bd;
	color: #fff;
}

.header-eg {
	float: right;
	margin: 9px 0 0 0;
	font-size: 15px;
}

.header__action_link {
	display: inline-block;
	color: #fff;
	text-decoration: none;
	vertical-align: middle;
	-moz-transition: all ease-out 0.2s;
	-webkit-transition: all ease-out 0.2s;
	-o-transition: all ease-out 0.2s;
	transition: all ease-out 0.2s;
	&:hover,
	&:focus {
		color: #bcb4c3;
		text-decoration: none;
	}
	&.header__login {
		&:before {
			display: inline-block;
			content: "";
			width: 18px;
			height: 10px;
			vertical-align: middle;
			margin: -2px 10px 0 0;
			background: url("./../svg/icon-login.svg") no-repeat;
		}
	}
	&.header__registration {
		margin-left: 25px;
		&:before {
			display: inline-block;
			content: "";
			width: 14px;
			height: 14px;
			vertical-align: middle;
			margin-right: 10px;
			background: url("./../svg/icon-registration.svg") no-repeat;
		}
	}
	&.header__logout {
		&:before {
			display: inline-block;
			content: "";
			width: 14px;
			height: 14px;
			vertical-align: middle;
			margin-right: 10px;
			background: url("./../svg/icon-logout.svg") no-repeat;
		}
	}
	&.header__personal {
		margin-left: 25px;
		&:before {
			display: inline-block;
			content: "";
			width: 14px;
			height: 14px;
			vertical-align: middle;
			margin-right: 10px;
			background: url("./../svg/icon-personal.svg") no-repeat;
		}
	}
}

.header-center {
	background: #ffffff url("./../images/bg-1.png") 68% 0 no-repeat;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.logo {
	float: left;
	margin: 10px 60px 0 0;
}

.header-fb {
	float: left;
	margin: 14px 40px 18px 0;
}

.header-phone {
	color: #000;
	font-size: 18px;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	margin: 0 0 8px 0;
	text-decoration: none;
	display: inline-block;
	&:hover,
	&:focus {
		color: #000;
	}
}

.header-hours {
	color: #737373;
	font-size: 14px;
	display: block;
	line-height: 1.4;
	p {
		margin: 0;
	}
}

.header-call {
	font-size: 14px;
	a,
	label {
		cursor: pointer;
		color: #424242;
		line-height: 1.4;
		display: inline-block;
		text-decoration: none;
		background: url("./../svg/icon-dots.svg") bottom repeat-x;
		margin: 0 15px 0 0;
		&:hover,
		&:focus {
			color: #424242;
			background: none;
		}
	}
}

.header-basket {
	float: right;
	padding: 7px 0 0 55px;
	margin: 12px 0 0 0;
	color: #4b4b4b;
	font-size: 14px;
	position: relative;
	text-decoration: none;
	&:hover,
	&:focus {
		color: #000;
	}
	&:before {
		display: block;
		position: absolute;
		content: "";
		top: 7px;
		left: 0;
		width: 32px;
		height: 42px;
		background: url("./../svg/icon-cart.svg") no-repeat;
	}
}

.header-basket__count {
	font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
	position: absolute;
	top: 0;
	left: 20px;
	min-width: 23px;
	padding: 0 3px;
	line-height: 23px;
	background: #f55b5b;
	border-radius: 50%;
	text-align: center;
	color: #fff;
}

.header-basket__title {
	color: #000;
	font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
	font-size: 15px;
	text-transform: uppercase;
	margin-bottom: 7px;
}
.header-bottom {
	margin-bottom: 20px;
}

.header-bottom__inner {
	background: #88c329;
	box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.05);
	border-radius: 0 0 4px 4px;
	height: 50px;
}

.nav-bottom-left__menu {
	margin: 0;
	border-radius: 0 0 0 4px;
	list-style: none;
	padding: 0;
	font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
	float: left;
	text-transform: uppercase;
	font-size: 13px;
	line-height: 50px;
	color: #fff;
}

.nav-bottom-left__menu .nav-item {
	margin: 0;
	display: inline-block;
	position: relative;
}

.nav-bottom-left__menu .nav-link {
	text-decoration: none;
	display: block;
	position: relative;
	color: #fff;
	margin: 0;
	padding: 0 20px;
}

.nav-bottom-left__menu .nav-link:hover,
.nav-bottom-left__menu .nav-link:focus {
	color: #fff;
	text-decoration: underline;
}

.nav-bottom-left__menu li:first-child .nav-link {
	border-radius: 0 0 0 4px;
}

.nav-bottom-left__menu .nav-link::after {
	content: "";
	display: block;
	position: absolute;
	background: #abd569;
	top: 15px;
	right: 0;
	width: 1px;
	height: 30px;
}
.nav-bottom-left__menu .active .nav-link,
.nav-bottom-left__menu .active .nav-link:hover,
.nav-bottom-left__menu .active .nav-link:focus {
	color: #fff;
	text-decoration: none;
	background: #f55b5b;
}

.nav-bottom-left__menu .active .nav-link::after,
.nav-bottom-left__menu li:last-child .nav-link:after {
	display: none;
}

.navbar-search {
	float: right;
	width: 340px;
	.search-query {
		box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.05);
		font-size: 16px;
		line-height: 50px;
		border: none;
		border-radius: 0;
		margin: 0;
		padding: 0 25px;
		height: 50px;
		width: 290px;
		float: left;
		background: #f6fdff;
		color: #000;
		&:focus {
			background-color: #fff;
			border: none;
			border-radius: 0;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.05);
		}
	}
	.icon-search {
		background: url("./../svg/icon-search.svg") no-repeat;
	}
	.submit {
		display: inline-block;
		box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.05);
		float: right;
		vertical-align: top;
		width: 50px;
		height: 50px;
		border: none;
		color: #fff;
		background-color: #f55b5b;
		cursor: pointer;
		position: relative;
		text-indent: -9000%;
		border-radius: 0 0 4px 0;
		&:hover,
		&:focus {
			background-color: #88c328;
		}
	}
}

.left-block {
	float: left;
	width: 250px;
	margin-right: 30px;
	position: relative;
}

.left-block::before {
	position: absolute;
	content: "";
	display: block;
	width: 105px;
	height: 413px;
	top: 70px;
	left: -100px;
	background: url("./../images/bg-2.png") no-repeat;
	z-index: -1;
}

.right-block {
	float: left;
	width: 830px;
	position: relative;
}

.owl-main-slider {
	margin-bottom: 10px;
}

.owl-main-slider .owl-dots {
	position: absolute;
	bottom: 40px;
	right: 40px;
}

.owl-carousel button.owl-dot {
	display: inline-block;
	margin: 0 0 0 10px;
	border: #c4c4c4 solid 2px;
	background: #fff;
	width: 11px;
	height: 11px;
	outline: 0;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.owl-carousel button.owl-dot.active {
	background: #f55b5b;
	border-color: #f55b5b;
}

.left-menu__wrap {
	background: #fff;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	margin-bottom: 30px;
	padding: 17px 20px;
}

.left-menu {
	list-style: none;
	padding: 0;
	margin: 0;
	> li {
		font-size: 12px;
		border-bottom: #ededed solid 1px;
		> ul {
			list-style: none;
			margin: 5px 0 18px 17px;
			padding: 0;
			font-size: 13px;
			color: #5e5e5e;
			> li {
				margin-bottom: 8px;
				> a {
					color: #5e5e5e;
					text-decoration: none;
					&:hover,
					&:focus {
						color: #000;
						text-decoration: none;
					}
				}
				&.active {
					> a {
						color: #000;
						text-decoration: none;
					}
				}
			}
		}
		&.open {
			a {
				&:after {
					background: url("./../svg/icon-menu-open-arrow.svg") no-repeat;
				}
				&:hover,
				&:focus {
					&:after {
						-webkit-transform: rotate(180deg);
						-ms-transform: rotate(180deg);
						-o-transform: rotate(180deg);
						filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=4);
					}
				}
			}
		}
		> a {
			color: #010101;
			padding: 13px 0;
			font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
			text-transform: uppercase;
			text-decoration: none;
			position: relative;
			display: block;
			&:hover,
			&:focus {
				color: #f55b5b;
				text-decoration: none;
				&:after {
					-moz-transform: rotate(90deg);
					-webkit-transform: rotate(90deg);
					-ms-transform: rotate(90deg);
					-o-transform: rotate(90deg);
					filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
				}
			}
			&:after {
				position: absolute;
				content: "";
				display: block;
				width: 21px;
				height: 21px;
				top: 50%;
				right: 0;
				margin-top: -10px;
				background: url("./../svg/icon-menu-arrow.svg") no-repeat;
				-moz-transition: all ease-out 0.2s;
				-webkit-transition: all ease-out 0.2s;
				-o-transition: all ease-out 0.2s;
				transition: all ease-out 0.2s;
			}
		}
		&:last-child {
			border: none;
		}
	}
}

.left-social {
	background: #fff;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	margin-bottom: 30px;
}

.left-news {
	margin-bottom: 30px;
}

.left-news .nav-tabs {
	background: #fff;
	border-radius: 4px;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
}

.left-news .nav-tabs .nav-item {
	width: 50%;
	margin: 0;
}

.left-news .nav-tabs .nav-link {
	border: none;
	color: #010101;
	text-align: center;
	font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
	font-size: 14px;
	text-transform: uppercase;
	padding: 16px;
	position: relative;
	text-decoration: none;
}

.left-news .nav-tabs li:first-child .nav-link {
	border-radius: 4px 0 0 4px;
}

.left-news .nav-tabs li:last-child .nav-link {
	border-radius: 0 4px 4px 0;
}

.left-news .nav-tabs .nav-item.show .nav-link,
.left-news .nav-tabs .nav-link.active {
	color: #fff;
	border: none;
	background: #f55b5b;
	border-radius: 0;
	text-decoration: none;
}

.left-news .nav-tabs .nav-link.active::before {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	bottom: -4px;
	left: 50%;
	margin: -4px;
	border-width: 8px 8px 0;
	border-top-color: #f55b5b;
	z-index: 200;
}

.left-news__wrap {
	background: #f8fcfd;
	padding: 20px 15px 2px 15px;
}

.left-news__all {
	background: #fff;
	border-radius: 4px;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	padding: 16px 32px;
	color: #2d2d2d;
	font-size: 14px;
	display: block;
	position: relative;
	text-decoration: none;
	&:after {
		right: 25px;
		margin-top: -11px;
	}
	&:hover,
	&:focus {
		color: #000;
		text-decoration: none;
	}
	&:hover:after,
	&:focus:after {
		right: 22px;
	}
}

.news-main {
	margin-bottom: 30px;
	display: block;
	text-decoration: none;
	font-size: 14px;
	color: #646464;
}
.news-main:hover, .news-main:focus {
	color: #646464;
}

.news-main__img {
	float: left;
	border-radius: 4px;
	opacity: 1;
	-webkit-transition-timing-function: ease-out;
	-webkit-transition-duration: 250ms;
	-moz-transition: opacity;
	-moz-transition-timing-function: ease-out;
	-moz-transition-duration: 250ms;
	-webkit-transition: all 1000ms;
	-o-transition: all 1000ms;
	transition: all 1000ms;
}

.news-main:hover .news-main__img {
	opacity: 0.8;
}

.news-main__inner {
	margin-left: 85px;
}

.news-main__date {
	color: #9b9b9b;
	font-size: 12px;
	margin-bottom: 3px;
}

.news-main__title {
	color: #000;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 15px;
	margin-bottom: 3px;
}

.news-main:hover .news-main__title,
.news-main:focus .news-main__title {
	color: #f55b5b;
}

.left-reviews {
	background: #fff;
	border-radius: 4px;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	padding: 25px;
	margin-bottom: 30px;
}

.left-reviews__title {
	color: #000;
	font-size: 14px;
	font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
	text-transform: uppercase;
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: #d1d1d1 solid 1px;
}

.reviews-main {
	margin-bottom: 30px;
	font-size: 14px;
}

.reviews-main__name {
	font-size: 13px;
	color: #b2b2b2;
	margin: 5px 0;
}

.left-reviews__all {
	padding-top: 20px;
	border-top: #d1d1d1 solid 1px;
	color: #2d2d2d;
	font-size: 14px;
	display: block;
	position: relative;
	text-decoration: none;
	&:after {
		right: 0;
		margin-top: -2px;
	}
	&:hover,
	&:focus {
		color: #000;
		text-decoration: none;
	}
	&:hover:after,
	&:focus::after {
		right: -3px;
	}
}

.icon-right-arrow {
	&:after {
		position: absolute;
		content: "";
		display: block;
		width: 22px;
		height: 22px;
		top: 50%;
		background: url("./../svg/icon-red-circle-white-right-arrow.svg") no-repeat;
		-moz-transition: all ease-out 0.2s;
		-webkit-transition: all ease-out 0.2s;
		-o-transition: all ease-out 0.2s;
		transition: all ease-out 0.2s;
	}
}

.good-main {
	margin-top: 25px;
}

.main-title__wrap {
	position: relative;
	padding: 0 9px;
	&:before {
		left: 0;
	}
	&:after {
		right: 0;
	}
	&:before,
	&:after {
		position: absolute;
		top: 50%;
		margin-top: -4px;
		content: "";
		display: block;
		width: 9px;
		height: 9px;
		background: url("./../svg/icon-red-circle-white-center.svg") no-repeat;
	}
}

.main-title {
	text-align: center;
	overflow: hidden;
	position: relative;
	margin-bottom: 25px;
}

.main-title:before,
.main-title:after {
	content: "";
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	height: 1px;
	background-color: #d2d2d2;
	position: relative;
}

.main-title:before {
	margin-left: -100%;
	left: -16px;
}

.main-title:after {
	margin-right: -100%;
	right: -16px;
}

.main-title h3 {
	display: inline-block;
	vertical-align: middle;
	padding: 10px 0 0 0;
	margin-bottom: 0;
	font-family: "ArnoPro-Smbd", "Times New Roman", Times, serif;
	color: #000;
	text-transform: uppercase;
	font-size: 30px;
}

.main-title h3 a,
.main-title h3 a:hover,
.main-title h3 a:focus {
	color: #000;
	text-decoration: none;
}

.good-main__wrap {
	margin: 0 -5px;
	.good-item__img {
		min-height: 228px;
	}
}

.good-item {
	vertical-align: top;
	display: inline-block;
	margin: 0 5px 15px 5px;
	padding-bottom: 10px;
	background: #fff;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	width: 197px;
	-moz-transition: all ease-out 0.2s;
	-webkit-transition: all ease-out 0.2s;
	-o-transition: all ease-out 0.2s;
	transition: all ease-out 0.2s;
	position: relative;
	&:hover,
	&:focus {
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);	
	}
}

.good-item__wrap {
	display: block;
	padding: 15px 10px 10px 10px;
	color: #000;
	text-align: center;
}

.good-item__title {
	color: #000;
	height: 52px;
	font-size: 14px;
	text-decoration: none;
	display: -webkit-box;
	vertical-align: middle;
	-webkit-box-orient: vertical;
	overflow: hidden;
	word-wrap: break-word;
	margin-bottom: 10px;
	-moz-transition: all ease-out 0.2s;
	-webkit-transition: all ease-out 0.2s;
	-o-transition: all ease-out 0.2s;
	transition: all ease-out 0.2s;
	&:after {
		content: "";
		position: absolute;
		top: 48px;
		right: 0;
		left: 0;
		height: 23px;
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
		pointer-events: none;
	}
}

.card-other__item .good-item__title {
	height: 44px;
	font-size: 13px;
	&:after {
		content: "";
		position: absolute;
		top: 40px;
		right: 0;
		left: 0;
		height: 23px;
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
	}
}

.good-item__label {
	margin: 0 -10px 7px -10px;
	height: 30px;
	div {
		display: inline-block;
		margin: 0 1px 3px 1px;
		color: #fff;
		padding: 6px 8px;
		font-size: 12px;
		font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
		border-radius: 13px;
		position: relative;
	}
}

.good-item__label-new {
	background-color: #92a4d4;
	text-transform: uppercase;
}

.good-item__label-tester {
	background-color: #acd435;
}

.good-item__label-hit {
	background-color: #f55b5b;
	text-transform: uppercase;
	&:before {
		content: "";
		display: inline-block;
		background: url("./../svg/icon-hit.svg") no-repeat;
		width: 9px;
		height: 8px;
		margin: 0 3px 0 0;
	}
}

.good-item__img {
	padding: 7px;
	margin-bottom: 10px;
	position: relative;
	overflow: hidden;
}

.card-other__item .good-item__img {
	padding: 0 30px;
}

.good-item__img img {
	margin: 0 auto;
	display: block;
	opacity: 1;
	-webkit-transition-timing-function: ease-out;
	-webkit-transition-duration: 250ms;
	-moz-transition: opacity;
	-moz-transition-timing-function: ease-out;
	-moz-transition-duration: 250ms;
	-webkit-transition: all 1000ms;
	-o-transition: all 1000ms;
	transition: all 1000ms;
}

.good-item:hover .good-item__img img {
	opacity: 0.9;
	-webkit-transform: scale(1.05, 1.05);
	-o-transform: scale(1.105, 1.05);
	transform: scale(1.05, 1.05);
}

.good-item__price-old {
	color: #929292;
	font-size: 12px;
	margin-bottom: 5px;
}

.good-item__price {
	font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
	font-size: 18px;
	color: #000;
	.price-old {
		color: #929292;
		font-size: 12px;
		margin-top: 5px;
	}
}

.card-other__price {
	font-size: 15px;
}

.good-item__price span {
	color: #535353;
	font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
	font-size: 12px;
}

.good-item__art {
	text-align: center;
	margin: 0 10px 12px 10px;
	color: #6c6c6c;
	font-size: 12px;
}

.add-basket {
	display: block;
	border: #dedede solid 1px;
	border-radius: 4px;
	padding: 11px;
	font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
	color: #000;
	font-size: 16px;
	text-decoration: none;
	text-align: center;
	margin: 0 10px;
	-moz-transition: all ease-out 0.2s;
	-webkit-transition: all ease-out 0.2s;
	-o-transition: all ease-out 0.2s;
	transition: all ease-out 0.2s;
}

.add-basket:hover,
.add-basket:focus {
	color: #fff;
	background: #f55b5b;
	border-color: #f55b5b;
}

.good-sale {
	text-align: center;
	margin: 0 10px 15px 10px;
	color: #929292;
	font-size: 12px;
}

.good-time {
	margin: 0 auto;
	span {
		display: inline-block;
		width: 40px;
		i {
			vertical-align: top;
			display: block;
			background: url("./../svg/icon-countdown-background.svg") no-repeat;
			width: 41px;
			height: 30px;
			font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
			color: #000;
			font-style: normal;
			font-size: 16px;
			line-height: 30px;
			letter-spacing: 12px;
			text-align: center;
			margin: 5px 0;
			padding: 0 0 0 5px;
		}
	}
}

.good-divide {
	display: inline-block;
	vertical-align: top;
	font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
	color: #000;
	width: 3px;
	height: 18px;
	font-size: 18px;
	margin: 8px 5px 0 5px;
}

.good-item__list {
	display: block;
	margin: 0 0 15px 0;
	padding: 20px;
	background: #fff;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	-moz-transition: all ease-out 0.2s;
	-webkit-transition: all ease-out 0.2s;
	-o-transition: all ease-out 0.2s;
	transition: all ease-out 0.2s;
	position: relative;
	&:hover,
	&:focus {
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
	}
	.good-item__title {
		-webkit-line-clamp: 1;
		height: 18px;
	}
	.good-item__art {
		text-align: left;
		margin: 12px 0 0 0;
	}
	.good-item__price {
		float: left;
		margin: 30px 0 0 15px;
	}
	.add-basket,
	.report {
		float: right;
		margin: 20px 0 0 15px;
		width: 220px;
	}
	.not-in-stock {
		opacity: 0.5;
		&:after {
			-moz-transform: rotate(-15deg);
			-webkit-transform: rotate(-15deg);
			-ms-transform: rotate(-15deg);
			-o-transform: rotate(-15deg);
			filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1.5);
			top: 32%;
			left: inherit;
			right: 0;
		}
	}
}

.good-item__list-img {
	float: left;
	width: 90px;
}

.good-item__list-wrap {
	margin-left: 15px;
	float: left;
	position: relative;
	width: 310px;
	color: #000;
	text-decoration: none;
	&:hover,
	&:focus {
		color: #000;
	}
}

.good-item__tab {
	.good-item__title {
		margin: 15px 0 0 0;
	}
	.good-item__price {
		margin-top: 12px;
	}
	.add-basket,
	.report {
		margin-top: 5px;
	}
	.not-in-stock {
		&:after {
			-moz-transform: rotate(-10deg);
			-webkit-transform: rotate(-10deg);
			-ms-transform: rotate(-10deg);
			-o-transform: rotate(-10deg);
			filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
			top: 5%;
		}
	}
}

.text-main {
	margin-top: 20px;
	margin-bottom: 50px;
}

.text-main h1 {
	color: #000;
	font-size: 20px;
	text-transform: inherit;
	border-bottom: #dddddd solid 1px;
	margin-bottom: 25px;
}

.text-main h1 span {
	display: inline-block;
	padding-bottom: 15px;
	border-bottom: #abd334 solid 3px;
}

footer {
	background: #806894 url("./../images/bg-3.png") 68% bottom no-repeat;
	color: #fff;
	font-size: 14px;
}

footer a,
footer a:hover,
footer a:focus {
	color: #fff;
}

.footer-top {
	background: #694e81;
}

.footer-bottom__inner {
	height: 50px;
}

.nav-footer-left__menu {
	margin: 0;
	list-style: none;
	padding: 0;
	font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
	float: left;
	text-transform: uppercase;
	font-size: 13px;
	line-height: 50px;
	color: #fff;
}

.nav-footer-left__menu .nav-item {
	margin: 0 25px 0 0;
	display: inline-block;
	position: relative;
}

.nav-footer-left__menu .nav-link {
	text-decoration: none;
	display: block;
	position: relative;
	color: #e3dde7;
	margin: 0;
	padding: 0;
}

.nav-footer-left__menu .nav-link:hover,
.nav-footer-left__menu .nav-link:focus {
	color: #e3dde7;
	text-decoration: underline;
}

.nav-footer-left__menu .active .nav-link,
.nav-footer-left__menu .active .nav-link:hover,
.nav-footer-left__menu .active .nav-link:focus {
	color: #fff;
	text-decoration: none;
}

footer .navbar-search .search-query {
	box-shadow: none;
	background: #5e4575;
	color: #fff;
}

footer .navbar-search .search-query:focus {
	background: #563c6b;
	box-shadow: none;
	color: #fff;
}

footer .navbar-search .submit {
	border-radius: 0;
	box-shadow: none;
}

footer .form-control:-moz-placeholder {
	color: #e3dde7;
}

footer .form-control::-o-placeholder {
	color: #e3dde7;
}

footer .form-control:-ms-input-placeholder {
	color: #e3dde7;
}

footer .form-control::-webkit-input-placeholder {
	color: #e3dde7;
}

.footer-center {
	padding: 40px 0;
}

.footer-center__left {
	float: left;
	max-width: 500px;
	margin-right: 60px;
	font-size: 13px;
}

.footer-center__center {
	float: left;
}

.footer-center__left p {
	margin: 0;
}

.footer-phone {
	margin-bottom: 10px;
}

.footer-phone a {
	font-size: 18px;
	color: #fff;
	text-decoration: none;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
}

.footer-center__right {
	float: right;
}

.footer-bottom {
	border-top: #8c779e solid 1px;
	padding: 20px 0;
}

.footer-bottom__left {
	float: left;
	margin-right: 60px;
}

.footer-bottom__center {
	float: left;
}

.footer-bottom__right {
	float: right;
}

.cat-item {
	padding: 0;
	color: #fff;
	text-decoration: none;
	text-align: center;
	margin-bottom: 35px;
	&:hover,
	&:focus {
		color: #fff;
	}

	img {
		border-radius: 4px;
	}
}

.cat-item__title {
	padding: 18px 10px 10px 10px;
	color: #fff;
	top: 0;
	left: 0;
	right: 0;
	text-transform: uppercase;
	border-radius: 4px 4px 0 0;
	background-color: #f55b5b;
	font-family: "ArnoPro-Smbd", "Times New Roman", Times, serif;
	font-size: 17px;

	&:before {
		position: absolute;
		content: "";
		width: 24px;
		height: 24px;
		left: 50%;
		margin: -8px 0 0 -12px;
		top: 0;
		z-index: 10;
		background: url("./../svg/icon-catalog-category.svg") no-repeat;
	}
}

.cat-item:nth-child(n+5) .cat-item__title {
	background-color: #987ead;
}

.cat-item:nth-child(n+9) .cat-item__title {
	background-color: #f55b5b;
}

.cat-item:nth-child(n+13) .cat-item__title {
	background-color: #987ead;
}

.cat-item:nth-child(n+17) .cat-item__title {
	background-color: #f55b5b;
}

.cat-item:nth-child(n+21) .cat-item__title {
	background-color: #987ead;
}

.img-left {
	float: left;
	margin: 0 25px 10px 0;
}

.img-right {
	float: right;
	margin: 0 0 10px 25px;
}

.cat-block {
	margin-bottom: 45px;
	position: relative;
	border-bottom: #d2d2d2 solid 1px;
	padding-bottom: 25px;
	&:before,
	&:after {
		position: absolute;
		bottom: -5px;
		content: "";
		display: block;
		width: 9px;
		height: 9px;
		z-index: 10;
		background: url("./../svg/icon-red-circle-white-center.svg") no-repeat;
	}

	&:before {
		left: 0;
	}

	&:after {
		right: 0;
	}
}

.cat-inner-item {
	padding: 80px 25px 35px 25px;
	color: #000;
	&:hover,
	&:focus {
		color: #000;
	}

	&:before {
		position: absolute;
		content: "";
		width: 27px;
		height: 27px;
		left: 50%;
		margin: 0 0 0 -13px;
		bottom: -13px;
		z-index: 10;
		background: url("./../svg/icon-catalog-category-item.svg") no-repeat;
	}

	&:hover:before,
	&:focus::before {
		background: url("./../svg/icon-catalog-category-item-hover.svg") no-repeat;
	}
}

.cat-inner-item__title {
	position: absolute;
	padding: 25px 15px 0 15px;
	color: #000;
	vertical-align: middle;
	top: 0;
	left: 0;
	right: 0;
	border-radius: 4px 4px 0 0;
	font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
	font-size: 16px;
}

.sort {
	border-top: #dadada solid 1px;
	border-bottom: #dadada solid 1px;
	padding: 15px 0;
	margin-bottom: 30px;
	font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
	color: #6c868e;
	font-size: 15px;
	line-height: 1.3;
}

.sort-left {
	float: left;
}

.sort-s1 {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	a {
		display: inline-block;
		font-family: "SFUIDisplay-Regular", Arial, Helvetica, sans-serif;
		font-size: 14px;
		vertical-align: middle;
		margin: 9px 17px;
		color: #000;
		text-decoration: none;
		background: url("./../svg/icon-dots.svg") bottom repeat-x;

		&.active {
			background: #f55b5b;
			color: #fff;
			margin: 0;
			padding: 9px 17px;
			border-radius: 17px;
			&:hover,
			&:focus {
				color: #fff;
				background: #d44343;
			}

			&:after {
				content: "";
				display: inline-block;
				vertical-align: middle;
				margin-left: 10px;
				width: 8px;
				height: 9px;
			}
			&.asc {
				&:after {
					background: url("./../svg/icon-sort-asc.svg") no-repeat;
				}
			}
			&.desc {
				&:after {
					background: url("./../svg/icon-sort-desc.svg") no-repeat;
				}
			}
		}

		&:hover,
		&:focus {
			color: #000;
			background: none;
		}
	}
}

.sort-text {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.sort-reset {
	float: right;
	color: #303030;
	text-decoration: none;
	margin-top: 9px;
	&:before {
		content: "";
		display: inline-block;
		margin-right: 5px;
		vertical-align: middle;
		width: 21px;
		height: 21px;
		background: url("./../svg/icon-filter-reset.svg") no-repeat;
	}
	&:hover,
	&:focus {
		color: #303030;
		span {
			background: none;
		}
	}
	span {
		background: url("./../svg/icon-dots.svg") bottom repeat-x;
	}
}

.sort-s2 {
	display: inline-block;
	vertical-align: middle;
	margin-right: 30px;
}

.catalog__view_container {
	display: inline-block;
	vertical-align: middle;
	border: #d3d3d3 solid 1px;
	border-radius: 4px;
	.catalog__view_selector {
		float: left;
		width: 34px;
		height: 34px;
		background-repeat: no-repeat;
		border-right: #d3d3d3 solid 1px;
		&.active,
		&:hover,
		&:focus {
			background-color: #f55b5b;
		}
		&:first-child {
			border-radius: 4px 0 0 4px;
		}
		&:last-child {
			border: none;
			border-radius: 0 4px 4px 0;
		}
		&.catalog__view_tile {
			background-image: url("./../svg/icon-catalog-tile.svg");
			&.active,
			&:hover,
			&:focus {
				background-image: url("./../svg/icon-catalog-tile-active.svg");
			}
		}
		&.catalog__view_list {
			background-image: url("./../svg/icon-catalog-list.svg");
			&.active,
			&:hover,
			&:focus {
				background-image: url("./../svg/icon-catalog-list-active.svg");
			}
		}
		&.catalog__view_table {
			background-image: url("./../svg/icon-catalog-table.svg");
			&.active,
			&:hover,
			&:focus {
				background-image: url("./../svg/icon-catalog-table-active.svg");
			}
		}
	}
}

.not-in-stock {
	.good-item__img {
		opacity: 0.5;
	}

	.good-item__price {
		color: #a1a1a1;
		span {
			color: #a1a1a1;
		}
	}

	&:after {
		content: "нет в наличии";
		position: absolute;
		-moz-transform: rotate(-30deg);
		-webkit-transform: rotate(-30deg);
		-ms-transform: rotate(-30deg);
		-o-transform: rotate(-30deg);
		filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
		top: 42%;
		left: 50%;
		margin: 0 0 0 -85px;
		width: 170px;
		border: solid 3px #62427c;
		font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
		padding: 10px;
		font-size: 14px;
		border-radius: 4px;
		text-align: center;
		text-transform: uppercase;
		color: #62427c;
		text-decoration: none;
	}
}

.report {
	display: block;
	border: #dedede solid 1px;
	border-radius: 4px;
	padding: 15px 11px 12px 11px;
	font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
	color: #6da414;
	font-size: 12px;
	text-decoration: none;
	text-align: center;
	margin: 0 10px;
	-moz-transition: all ease-out 0.2s;
	-webkit-transition: all ease-out 0.2s;
	-o-transition: all ease-out 0.2s;
	transition: all ease-out 0.2s;
	&:hover,
	&:focus {
		color: #fff;
		background: #6da414;
		border-color: #6da414;
	}
}

.sort-type {
	margin-bottom: 35px;
	a,
	span {
		float: left;
		font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
		font-size: 15px;
		color: #fff;
		text-transform: uppercase;
		padding: 15px 35px;
		text-decoration: none;
		background: #977eac;
		position: relative;
	}

	a {
		&:hover,
		&:focus {
			background: #5d4076;
		}

		&:first-child {
			border-radius: 4px 0 0 4px;
		}

		&:last-child {
			border-radius: 0 4px 4px 0;
		}
	}

	.active {
		&:hover,
		&:focus,
		& {
			background: #f55b5b;
		}

		&:after {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			border-color: transparent;
			border-style: solid;
			bottom: -4px;
			left: 50%;
			margin: -4px;
			border-width: 8px 8px 0;
			border-top-color: #f55b5b;
			z-index: 200;
		}
	}
}

.sort-brand {
	margin-bottom: 35px;
}

.sort-brand__title {
	border-bottom: #dadada solid 1px;
	padding: 0 0 12px 0;
	margin-bottom: 12px;
	color: #2d2d2d;
	font-size: 14px;
	font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
	text-transform: uppercase;
}

.sort-brand__list {
	float: left;
	width: 20%;
	padding: 0 10px 0 0;
	margin: 0;
	list-style: none;
	font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
	font-size: 14px;
	li {
		margin-bottom: 5px;
		a {
			color: #2d2d2d;
			text-decoration: none;
			padding: 3px 7px;
			display: inline-block;
			border-radius: 4px;

			&:hover,
			&:focus,
			&.active {
				color: #fff;
				background: #f55b5b;

				span {
					color: #fff;
				}
			}

			span {
				color: #9ab1b9;
			}
		}
	}
}

.card-left {
	width: 300px;
	float: left;
}

.card-right {
	float: left;
	width: 500px;
	margin-left: 30px;
	padding-bottom: 30px;
}

.card-img__main {
	border-radius: 4px;
	-moz-transition: all ease-out 0.2s;
	-webkit-transition: all ease-out 0.2s;
	-o-transition: all ease-out 0.2s;
	transition: all ease-out 0.2s;
	position: relative;
	text-align: center;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	background: #fff;
	display: block;
	text-decoration: none;
	padding: 15px 20px 15px 20px;
}

.card-img__main:hover,
.card-img__main:focus {
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
}

.card-art {
	margin: 10px 0 0 0;
	color: #6c6c6c;
	font-size: 12px;
}

.card-sexs {
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	color: #2d2d2d;
	margin-top: 5px;
	font-size: 16px;
}

.card-img__small-wrap {
	margin: 14px -4px 0 -5px;
}

.card-img__small {
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	-moz-transition: all ease-out 0.2s;
	-webkit-transition: all ease-out 0.2s;
	-o-transition: all ease-out 0.2s;
	transition: all ease-out 0.2s;
	padding: 12px;
	border: #fff solid 3px;
	border-radius: 4px;
	display: inline-block;
	width: 65px;
	background: #fff;
	margin: 0 4px 5px 5px;
	text-align: center;
}

.card-img__small:hover,
.card-img__small:focus {
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
	border: #f55b5b solid 3px;
}

.card__price {
	margin-top: 10px;
	font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
	color: #e53d3d;
	font-size: 22px;
	text-align: center;
}

.card__price span {
	color: #000;
}

.card__price-old {
	color: #717171;
	text-align: center;
	margin-top: 7px;
}
.card-button {
	text-align: center;
	margin-top: 15px;
	.report {
		font-size: 15px;
	}
}

.card-btn {
	display: block;
	width: 210px;
	margin: 0 auto 10px auto;
}

.card-click {
	color: #484e68;
	border-bottom: #484e68 dotted 2px;
	font-size: 16px;
	display: inline-block;
	font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
	text-decoration: none;
	-moz-transition: all ease-out 0.2s;
	-webkit-transition: all ease-out 0.2s;
	-o-transition: all ease-out 0.2s;
	transition: all ease-out 0.2s;
}

.card-click:hover,
.card-click:focus {
	border-bottom-color: #f55b5b;
}

.card-soc {
	text-align: center;
	margin: 20px 0;
}

.card-all {
	background: #f8fcfd;
	border-radius: 4px;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	padding: 25px 30px;
	font-size: 14px;
}

.card-all__title {
	color: #000;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 16px;
}

.card-all__list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.card-all__list li {
	margin: 15px 0 0 0;
	padding: 15px 0 0 0;
	border-top: #dddddd solid 1px;
}

.card-all__list li a {
	color: #323232;
	text-decoration: none;
}

.card-all__list li a:hover,
.card-all__list li a:focus,
.card-all__list .active a {
	color: #e53d3d;
}
.card-other {
	margin-bottom: 15px;
}

.card-other__title {
	color: #000000;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 18px;
	border-bottom: #d7d7d7 solid 1px;
	margin-bottom: 20px;
	padding-bottom: 15px;
}

.card-other__wrap {
	margin: 0 -3px 15px -3px;
}

.card-other__item {
	width: 160px;
	margin: 0 3px 12px 3px;
}
.card-other__item .add-basket {
	font-size: 13px;
	padding: 7px;
}

.card-tabs {
	border: none;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 15px;
}

.card-tab-content {
	background: #fff;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	padding: 35px;
	margin: 0 0 0 1px;
	font-size: 14px;
	margin-bottom: 30px;
}

.card-tabs .nav-item {
	margin: 0;
}

.card-tabs .nav-link {
	border-color: #e9ecef;
	padding: 17px 25px;
	border-bottom: none;
	color: #000000;
	text-decoration: none;
}
.card-tabs .nav-link:focus,
.card-tabs .nav-link:hover {
	border-color: #e9ecef #e9ecef #dee2e6;
}

.card-accordion {
	margin-bottom: 45px;
}

.card-accordion__item {
	border-bottom: 1px solid #cfcfcf;
	margin-bottom: 10px;
	padding: 0 0 10px 0;
}

.card-accordion__item:last-child {
	border: none;
}

.card-accordion__header {
	cursor: pointer;
	background-color: transparent;
	color: #575d96;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 16px;
	position: relative;
	> div {
		padding-left: 38px;
		&:before {
			position: absolute;
			display: block;
			left: 0;
			top: 0;
			content: "";
			background: url("./../svg/icon-accordion-item-close.svg");
			width: 21px;
			height: 21px;
		}
		&.collapsed {
			&:before {
				background: url("./../svg/icon-accordion-item-open.svg");
			}
		}
	}
}

.card-accordion__body {
	font-size: 14px;
	padding: 20px 0 0 0;
}

.card-reviews {
	margin-bottom: 30px;
}

.card-reviews__title {
	color: #000000;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 18px;
	border-bottom: #d7d7d7 solid 1px;
	margin-bottom: 20px;
	padding-bottom: 15px;
}

.big_grade_stars .star {
	display: inline-block;
	width: 18px;
	height: 18px;
	background-image: url("./../images/stars.png");
	background-position: -65px -18px;
	cursor: pointer;
}

.big-grade-stars-1 .star-1,
.big-grade-stars-2 .star-1,
.big-grade-stars-2 .star-2,
.big-grade-stars-3 .star-1,
.big-grade-stars-3 .star-2,
.big-grade-stars-3 .star-3,
.big-grade-stars-4 .star-1,
.big-grade-stars-4 .star-2,
.big-grade-stars-4 .star-3,
.big-grade-stars-4 .star-4,
.big-grade-stars-5 .star-1,
.big-grade-stars-5 .star-2,
.big-grade-stars-5 .star-3,
.big-grade-stars-5 .star-4,
.big-grade-stars-5 .star-5 {
	background-position: -65px 0px;
}

.grade_stars {
	display: inline-block;
	vertical-align: middle;
}

.grade_stars .star {
	display: inline-block;
	width: 14px;
	height: 14px;
	background-image: url("./../images/stars.png");
	background-position: -50px 0;
}

.grade-stars- {
	display: none;
}

.grade-stars-1 .star-1,
.grade-stars-2 .star-1,
.grade-stars-2 .star-2,
.grade-stars-3 .star-1,
.grade-stars-3 .star-2,
.grade-stars-3 .star-3,
.grade-stars-4 .star-1,
.grade-stars-4 .star-2,
.grade-stars-4 .star-3,
.grade-stars-4 .star-4,
.grade-stars-5 .star-1,
.grade-stars-5 .star-2,
.grade-stars-5 .star-3,
.grade-stars-5 .star-4,
.grade-stars-5 .star-5 {
	background-position: -50px -14px;
}

.card-reviews__wrap {
	margin-bottom: 20px;
	background: #fbfff1;
	border-radius: 4px;
	padding: 25px;
}

.card-reviews__wrap-all {
	margin-bottom: 25px;
	background: #fff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	padding: 25px;
}

.card-reviews__item {
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom: #e2e2e2 solid 1px;
}

.card-reviews__item:last-child {
	margin: 0;
	padding: 0;
	border: none;
}

.card-reviews__body {
	color: #464646;
	font-size: 14px;
}

.card-reviews__top {
	margin-bottom: 5px;
	vertical-align: middle;
}

.card-reviews__name {
	color: #000000;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 13px;
	display: inline-block;
	margin-right: 10px;
	vertical-align: middle;
}
.card-reviews__date {
	color: #747474;
	font-size: 13px;
	display: inline-block;
	margin-right: 10px;
	vertical-align: middle;
}

.card-reviews__adm {
	margin: 20px 0 0 0;
	background: #f1f7f9;
	border-radius: 4px;
	padding: 20px;
}
.card-reviews__adm-name {
	color: #000000;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 13px;
	margin-bottom: 5px;
}

.card-add-reviews {
	background: #fff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	font-size: 14px;
	color: #363636;
}

.card-add-reviews__top {
	border-bottom: #e2e2e2 solid 1px;
	padding: 25px 30px;
}

.card-add-reviews__title {
	color: #000000;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 18px;
	display: inline-block;
	vertical-align: middle;
}

.card-add-reviews__mark {
	display: inline-block;
	margin-left: 15px;
	padding-left: 15px;
	border-left: #d3d3d3 solid 1px;
	vertical-align: middle;
}

.card-add-reviews__bottom {
	padding: 25px 30px 35px 30px;
}

.card-add-reviews__mark .big_grade_stars {
	display: inline-block;
}

.card-add-reviews__bottom span {
	margin-bottom: 5px;
	display: block;
}

.captcha-reset {
	font-style: normal;
	border-bottom: #484e68 dotted 1px;
	cursor: pointer;
}

.card-add-reviews__bottom .capcha {
	margin-top: 10px;
}

.basket-btn-top {
	float: right;
	margin-top: -70px;
}

.cart {
	background: #fff;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	margin-bottom: 40px;
}

.cart-top {
	border-bottom: #e8e8e8 solid 1px;
	color: #787878;
	font-size: 12px;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	text-transform: uppercase;
	padding: 17px 35px;
	vertical-align: middle;
}

.popup-cart_empty {
	margin: 20px 0 0 0;
	text-align: center;
	font-size: 18px;
	line-height: 24px;
}

.cart-header__item-title {
	width: 318px;
	display: inline-block;
	vertical-align: middle;
}

.cart-header__item-amount {
	width: 285px;
	display: inline-block;
	vertical-align: middle;
}

.cart-header__item-price-total {
	width: 120px;
	display: inline-block;
	vertical-align: middle;
}

.cart__item {
	background: url("./../svg/dot-2.svg") bottom repeat-x;
	display: table;
	padding: 15px 35px;
	color: #252525;
	font-size: 16px;
	&:last-child {
		background: none;
	}
}

.cart-img {
	vertical-align: middle;
	display: table-cell;
	width: 68px;
}

.cart-title__wrap {
	display: inline-block;
}

.cart__item-title {
	vertical-align: middle;
	display: table-cell;
	width: 250px;
	padding: 0 20px 0 20px;
	a {
		color: #252525;
		text-decoration: none;
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}

.cart__item-amount {
	vertical-align: middle;
	display: table-cell;
	width: 121px;
	.cart__item-amount-control {
		width: 36px;
		height: 36px;
		cursor: pointer;
		display: inline-block;
		vertical-align: middle;
	}
	.cart__item-amount-increment-btn {
		background: url("./../svg/icon-cart-amount-inc.svg") no-repeat;
		margin-right: -3px;
	}
	.cart__item-amount-decrement-btn {
		background: url("./../svg/icon-cart-amount-dec.svg") no-repeat;
		margin-left: -3px;
	}
	.cart__item-input {
		display: inline-block;
		width: 47px;
		border-top: #dadada solid 1px;
		border-bottom: #dadada solid 1px;
		text-align: right;
		color: #878787;
		font-size: 15px;
		padding: 6px 10px;
		border-radius: 0;
		background: #fff;
		text-align: center;
		&:focus {
			background: #f1f6f9;
			padding: 6px 10px;
			border-radius: 0;
		}
	}
}

.cart__item-price {
	vertical-align: middle;
	display: table-cell;
	width: 155px;
	padding: 0 10px;
	color: #adadad;
	b {
		color: #000;
	}
}

.cart__item-cross {
	background: url("./../svg/icon-cart-item-cross.svg") no-repeat;
	display: inline-block;
	vertical-align: middle;
	width: 9px;
	height: 9px;
	margin: -3px 18px 0 0;
}

.basket-sum {
	vertical-align: middle;
	display: table-cell;
	width: 140px;
	padding: 0 15px;
	color: #adadad;
	b {
		color: #000;
		font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	}
}

.cart__item-remove-container {
	vertical-align: middle;
	display: table-cell;
	width: 26px;
	.cart__item-remove-button {
		display: inline-block;
		vertical-align: middle;
		background: url("./../svg/icon-cart-item-del.svg") no-repeat;
		width: 26px;
		height: 26px;
	}
}

.basket-bottom {
	margin-bottom: 30px;
}
.basket-bottom__promo {
	display: inline-block;
	width: 331px;
	margin-right: 20px;
}

.basket-bottom__delivery {
	display: inline-block;
	width: 225px;
	margin-right: 20px;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 17px;
	vertical-align: top;
}

.basket-bottom__sum {
	display: inline-block;
	width: 235px;
	vertical-align: top;
}

.basket-bottom__promo-wrap {
	display: inline-block;
}

.basket-bottom__promo .required {
	display: inline-block;
	width: 210px;
	border: #dadada solid 1px;
	border-right: none;
	color: #878787;
	font-size: 15px;
	padding: 8px 15px;
	border-radius: 4px 0 0 4px;
	background: #fff;
	vertical-align: top;
}

.basket-bottom__promo .prim {
	display: inline-block;
	border: none;
	margin-left: -3px;
	width: 120px;
	background: #977eac;
	color: #fff;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 16px;
	text-align: center;
	padding: 10px 15px;
	border-radius: 0 4px 4px 0;
}
.basket-bottom__promo-text {
	margin-top: 12px;
	color: #000000;
	font-size: 16px;
	font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
}

.basket-bottom__sum-top {
	text-align: right;
	color: #565656;
	margin-bottom: 5px;
	font-size: 17px;
}

.basket-bottom__sum-top span {
	color: #e42a2a;
	font-size: 18px;
	font-weight: bold;
}

.basket-bottom__sum-center {
	text-align: right;
	color: #565656;
	margin-bottom: 5px;
	font-size: 17px;
	b {
		font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
		color: #977eac;
		font-size: 18px;
		font-weight: bold;
	}
}

.basket-bottom__sum-bottom {
	text-align: right;
	color: #000;
	margin-bottom: 20px;
	font-size: 17px;
	b {
		font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
		color: #e42a2a;
		font-size: 21px;
		font-weight: bold;
	}
}

.why-cheap {
	display: block;
	position: relative;
	background: #f8fcfd;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	padding: 15px 15px 15px 60px;
	color: #383838;
	font-size: 14px;
	text-decoration: none;
	&:hover,
	&:focus {
		background: #fff;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
		color: #383838;
	}
	&:before {
		position: absolute;
		display: block;
		left: 15px;
		top: 18px;
		content: "";
		background: url("./../svg/icon-red-question.svg");
		width: 29px;
		height: 29px;
	}
}

.order-delivery-method {
	margin-bottom: 30px;
}

.order-delivery-title {
	background: #9bbde4;
	border-radius: 4px 4px 0 0;
	color: #fff;
	position: relative;
	padding: 21px 85px;
	font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
	text-transform: uppercase;
	&:before {
		position: absolute;
		display: block;
		left: 0;
		top: 0;
		content: "";
		background: #80acde;
		width: 60px;
		height: 60px;
		border-radius: 4px 0 0 0;
	}
	&:after {
		position: absolute;
		display: block;
		left: 21px;
		top: 22px;
		content: "";
		background: url("./../svg/icon-delivery-method.svg");
		width: 18px;
		height: 16px;
	}
}

.order-delivery-options {
	background: #fff;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	border-radius: 0 0 4px 4px;
	.radio {
		padding: 35px 35px 35px 80px;
		background: url("./../svg/dot-2.svg") bottom repeat-x;
		&:last-child {
			background: none;
		}
		label {
			padding-left: 0;
			color: #000000;
			font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
			font-size: 17px;
			margin-bottom: 10px;
			&:after {
				top: 0;
				left: -50px;
			}
		}
		.delivery-text {
			color: #565656;
			font-size: 15px;

			p {
				margin-bottom: 5px;
			}

			strong {
				color: #000;
				font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
				font-weight: normal;
			}
		}
	}
}

.order-contacts {
	margin-bottom: 50px;
}

.order-contacts-title {
	background: #9bbde4;
	border-radius: 4px 4px 0 0;
	color: #fff;
	position: relative;
	padding: 21px 85px;
	font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
	text-transform: uppercase;
	&:before {
		position: absolute;
		display: block;
		left: 0;
		top: 0;
		content: "";
		background: #80acde;
		width: 60px;
		height: 60px;
		border-radius: 4px 0 0 0;
	}

	&:after {
		position: absolute;
		display: block;
		left: 17px;
		top: 19px;
		content: "";
		background: url("./../svg/icon-order-contacts-form.svg");
		width: 26px;
		height: 22px;
	}
}

.order-contacts-form {
	background: #fafeff;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	border-radius: 0 0 4px 4px;
	padding: 35px;
	.form-group {
		.field__title {
			display: block;
			font-size: 14px;
			margin-bottom: 5px;
		}
	}
	.form-control {
		border: #d9d9d9 solid 1px;
		background: #fff;
		&:focus {
			background: #f1f6f9;
		}
	}
	input,
	textarea {
		font-size: 15px;
	}
}

.field-hint {
	display: inline-block;
	position: relative;
	&:hover {
		.field-hint-block {
			display: block;
		}
	}
	.field-hint-block {
		display: none;
		background: #fff;
		box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
		border-radius: 4px;
		position: absolute;
		padding: 10px;
		width: 150px;
		top: -30px;
		right: -150px;
		font-size: 12px;
	}

	.icon-field-hint {
		background: url("./../svg/icon-field-hint.svg") no-repeat;
		display: inline-block;
		vertical-align: middle;
		width: 22px;
		height: 22px;
	}
}

.personal-data-agree {
	font-size: 12px;
}

.reg-wrap {
	background: #fff;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	padding: 65px 100px;
	max-width: 570px;
	margin-bottom: 30px;
	.form-group {
		label {
			display: block;
			font-size: 14px;
			margin-bottom: 5px;
		}
		h4 {
			font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
		}
	}
}

.faq {
	background: #fff;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	padding: 35px;
	margin-bottom: 30px;
}

.faq .card-accordion {
	margin-bottom: 0;
}

.faq-table {
	border-radius: 4px;
	margin-bottom: 15px;
	border-collapse: separate;
	width: inherit;
	border: 1px solid #f55b5b;
	border-spacing: 0;
}

.faq-table td,
.faq-table th {
	padding: 11px 23px;
	border-left: 1px solid #f55b5b;
	border-top: 1px solid #f55b5b;
}

.faq-table th {
	border-top: none;
	background: #fff5d9;
}

.faq-table th:first-child {
	border-top-left-radius: 4px;
}

.faq-table th:last-child {
	border-top-right-radius: 4px;
}

.faq-table .yel-bg {
	background: #fffbef;
}

.faq-table .bord-top-none {
	border-top: none;
}

.faq-table .bord-left-none {
	border-left: none;
}

.tester-block {
	margin: 0 auto 40px auto;
	max-width: 580px;
	.tester-block-all {
		width: 280px;
		float: left;

		.tester-block-inner {
			border: #dadada solid 1px;
			border-radius: 4px;
			padding: 20px;
			text-align: center;
			margin-bottom: 30px;

			h4 {
				margin-top: 20px;
				color: #000000;
				font-size: 18px;
				margin-bottom: 20px;
			}
		}
	}
}

.tester-block-page {
	background-color: #fff;
	background-position: bottom;
	background-repeat: repeat-x;
	border-radius: 20px;
	width: 345px;
	-webkit-box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.2);
	box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.2);
	margin-right: 20px;
	&.tester-block-page-right {
		background-image: url("./../images/tester-block-right.png");
		.tester-block-page-inner {
			background-image: url("./../images/tester-img-right.png");
			background-position: center 20px;
			background-repeat: no-repeat;
			padding-top: 270px;
			padding-bottom: 10px;
		}
	}
}

.list-circle {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		padding-left: 25px;
		position: relative;
		margin-bottom: 10px;
		&:after {
			position: absolute;
			display: block;
			left: 0;
			top: 5px;
			content: "";
			background: url("./../svg/icon-red-circle-white-center.svg");
			width: 9px;
			height: 9px;
		}
	}
}

.quality-page {
	color: #282828;
	font-size: 12px;
	line-height: 17px;
	p {
		margin-bottom: 17px;
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			margin-bottom: 10px;
		}
	}
}

.text-size-14 {
	color: #696969;
	margin-bottom: 10px;
}

.text-size-18 {
	font-size: 18px;
	color: #000000;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
}

.text-size-18-norm {
	font-size: 18px;
	color: #000000;
}

.text-mar-left {
	margin-left: 13px;
}

.text-red {
	color: #f55b5b;
}

.text-pink {
	color: #f76868;
}

.quality-banner-top {
	border: #dedede solid 1px;
	border-radius: 4px;
	font-size: 18px;
	margin-bottom: 25px;
	position: relative;
	&:before {
		position: absolute;
		display: block;
		left: 50%;
		top: 50%;
		margin: -35px 0 0 -35px;
		content: "";
		background: url("./../images/libra.png");
		width: 70px;
		height: 70px;
	}
	p {
		margin-bottom: 5px;
	}
	strong {
		font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
	}
}

.quality-banner__left-block {
	float: left;
	min-height: 165px;
	width: 50%;
	background: #f1f7f9;
	border-radius: 4px 0 0 4px;
	padding: 30px 50px 20px 55px;
}

.quality-banner__right-block {
	float: left;
	min-height: 165px;
	width: 50%;
	border-radius: 0 4px 4px 0;
	padding: 30px 20px 20px 80px;
}

.quality-page .quality-btn {
	display: inline-block;
	margin-top: 5px;
	background: url("./../images/q-btn.png");
	border-radius: 10px;
	height: 32px;
	line-height: 32px;
	text-align: center;
	padding: 0 12px 0 8px;
	font-size: 14px;
	color: #fff;
	text-decoration: none;
}

.quality-page .quality-btn:hover {
	background: url("./../images/q-btn-hover.png");
	color: #fff;
}

.delivery-faq {
	margin-bottom: 30px;
}

.delivery-wrap {
	margin-bottom: 25px;
}

.delivery-calculator-data {
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	border-radius: 0 0 4px 4px;
	background: #fff;
}

.delivery-moscow-header {
	background: #86b1e3;
	border-radius: 4px 4px 0 0;
	padding: 20px 20px 20px 80px;
	color: #fff;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 20px;
	text-decoration: none;
	display: block;
	position: relative;
	&:hover,
	&:focus {
		color: #fff;
	}
	&:before {
		display: block;
		position: absolute;
		content: "";
		top: 50%;
		margin: -27px 0 0 0;
		left: 20px;
		width: 44px;
		height: 52px;
		background: url("./../images/delivery-moscow.png") no-repeat;
	}
}

.delivery-moscow-table {
	border-right: #e1e1e1 solid 1px;
	border-radius: 0 0 4px 4px;
	margin: 0;
}

.delivery-moscow-table p {
	margin-bottom: 7px;
}

.delivery-moscow-table td {
	padding: 12px 15px;
	color: #434343;
	font-size: 14px;
	background: #fff;
	border-left: #e1e1e1 solid 1px;
	border-top: #e1e1e1 solid 1px;
	border-bottom: #e1e1e1 solid 1px;
	border-right: #e1e1e1 solid 1px;
	vertical-align: middle;
}

.delivery-moscow-table .td-header {
	text-align: center;
	font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
	color: #000000;
	background: #f1f7f9;
	border-top: none;
	border-bottom: #e1e1e1 solid 1px;
	border-right: #e1e1e1 solid 1px;
}

.delivery-moscow-table .td-bord-right-none {
	border-right: none;
}

.delivery-moscow-table .td-bord-bottom-none {
	border-bottom: none;
}

.delivery-moscow-table .td-bord-rad-left {
	border-radius: 0 0 0 4px;
}

.delivery-moscow-table .td-bord-rad-right {
	border-radius: 0 0 4px 0;
}

.delivery-moscow-table .ks,
.delivery-moscow-table .pp {
	font-size: 15px;
	font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
}

.delivery-moscow-table img, .delivery-rus-table img {
	display: block;
	margin: 0 auto 7px auto;
}

.delivery-wrap .delivery-bottom {
	padding: 20px;
}

.pp {
	.map {
		margin-top: 15px;
		color: #535353;
		font-size: 13px;
		position: relative;
		padding-top: 18px;
		&:before {
			display: block;
			position: absolute;
			content: "";
			background: url("./../svg/icon-calculator-map.svg") no-repeat;
			width: 12px;
			top: 0;
			left: 50%;
			margin-left: -6px;
			height: 15px;
		}
		a {
			&:hover,
			&:focus,
			& {
				display: block;
			}
		}
	}
}

.delivery-moscow-table .sp-faq {
	width: 15px;
	height: 15px;
	background-position: -128px -555px;
	margin-left: 5px;
}

.delivery-rus-header {
	background: #88c328;
	border-radius: 4px 4px 0 0;
	padding: 20px 20px 20px 80px;
	color: #fff;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 20px;
	text-decoration: none;
	display: block;
	position: relative;
	&:hover,
	&:focus {
		color: #fff;
	}
	&:before {
		display: block;
		position: absolute;
		content: "";
		top: 50%;
		margin: -41px 0 0 0;
		left: 0;
		width: 82px;
		height: 82px;
		background: url("./../images/delivery-russia.png") no-repeat;
	}
}

.delivery-rus-header:hover .delivery-rus-turn {
	background-position: -115px -802px;
}

.delivery-rus-sdos {
	padding: 0 0 18px 0;
	border-left: #e1e1e1 solid 1px;
	border-right: #e1e1e1 solid 1px;
	text-align: center;
}

.delivery-rus-pnas {
	border-left: #e1e1e1 solid 1px;
	border-right: #e1e1e1 solid 1px;
	background: #fff;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	padding: 18px 25px;
	.form-control {
		display: inline-block;
		width: 400px;
		border: #dadada solid 1px;
		border-right: none;
		color: #878787;
		font-size: 15px;
		border-radius: 4px 0 0 4px;
		background: #fff;
		vertical-align: top;
	}

	.btn {
		display: inline-block;
		border: none;
		margin-left: -3px;
		border-radius: 0 4px 4px 0;
	}
}
.russian-delivery-destination {
	color: #e52c2c;
	margin-right: 10px;
}

.prepayment, .prepayment:hover,
.prepayment:focus {
	float: right;
	margin-top: -65px;
	width: 142px;
	height: 50px;
	text-indent: -9000%;
	text-decoration: none;
	background-position: 0 -839px;
}

.prepayment:hover {
	background-position: 0 -897px;
}

.reviews {
	margin-bottom: 30px;
}

.reviews-left {
	width: 500px;
	float: left;
	margin-right: 20px;
}
.reviews-right {
	width: 310px;
	float: left;
	background: #fff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	padding: 5px;
}

.reviews-contests {
	margin-bottom: 25px;
	color: #bababa;
}

.reviews-contests a {
	color: #3e4481;
}

.reviews .card-add-reviews {
	margin-bottom: 25px;
}

.pay-block {
	background: #fff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	padding: 35px;
	margin-bottom: 25px;
}

.quality-namb {
	list-style: none;
	padding: 0;
	margin: 0 0 15px 0;
	li {
		position: relative;
		padding-left: 43px;
		margin-bottom: 15px;
		vertical-align: middle;
		&:before {
			position: absolute;
			top: 50%;
			margin-top: -15px;
			width: 30px;
			height: 30px;
			background: #87c228;
			border-radius: 50%;
			left: 0;
			font-size: 13px;
			line-height: 30px;
			text-align: center;
			font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
			color: #fff;
		}
		@for $i from 1 through 10 {
			&:nth-child(#{$i}) {
				&:before {
					content: "#{$i}";
				}
			}
		}
	}
}

.pay-block__bottom {
	background: #fff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	margin-bottom: 25px;
}

.pay-block__title {
	color: #000;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 18px;
	border-bottom: #e2e2e2 solid 1px;
	padding: 25px 30px;
	vertical-align: middle;
}

.pay-block__item {
	border-bottom: #e3e3e3 solid 1px;
	padding: 35px 35px 35px 160px;
	position: relative;
}

.pay-block__item:last-child {
	border: none;
}

.pay-block__item-title {
	position: absolute;
	left: 0;
	top: 35px;
	background: #f55b5b;
	border-radius: 0 4px 4px 0;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	color: #fff;
	padding: 15px 25px;
	font-size: 15px;
}

.discounts {
	background: #fff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	margin-bottom: 25px;
}

.discounts-title {
	color: #f55b5b;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 18px;
	margin-bottom: 25px;
}

.discounts-item {
	border-bottom: #e3e3e3 solid 1px;
	padding: 35px 35px 20px 35px;
	position: relative;
}

.discounts-item:last-child {
	border: none;
}

.comparison {
	background: #fff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	margin-bottom: 25px;
}

.comparison-title {
	color: #000;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 18px;
	position: absolute;
	top: 0;
	left: 0;
	padding: 35px;
}

.comparison-item {
	background: url("./../svg/dot-2.svg") bottom repeat-x;
	padding: 35px 35px 20px 280px;
	position: relative;
}

.comparison-item:last-child {
	background: none;
}

.comparison-top {
	background: #fff;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	padding: 35px;
	margin-bottom: 30px;
}

.cont {
	background: #fff;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	padding: 35px;
	margin-bottom: 30px;
}

.cont-title {
	color: #000;
	font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
	font-size: 18px;
	margin-bottom: 5px;
}

.cont-map {
	margin: 35px 0;
}

.cont-form {
	border: #d4d4d4 solid 1px;
	border-radius: 4px;
	padding: 35px 45px;
	max-width: 650px;
	margin-top: 20px;
	.cont-title {
		margin: 0 0 25px 0;
	}
}

.cart-popup-container {
	position: fixed;
	top: 97px;
	right: 5%;
	margin: 0;
	padding: 0;
	border: 0;
	z-index: 1100;
	.block-out {
		width: 690px;
		min-height: 340px;
		max-height: 405px;
		overflow: hidden;
		background: #fff;
		box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
		border-radius: 4px;
		border: #977eac solid 2px;
	}
	.block-in {
		position: relative;
		overflow-y: auto;
		overflow-x: hidden;
		min-height: 180px;
		max-height: 270px;
	}
	.cart__title {
		padding: 20px 35px;
		background: url("./../svg/dot-2.svg") bottom repeat-x;
	}
	h4 {
		font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
		color: #090909;
		font-size: 14px;
		text-transform: uppercase;
		margin: 0;
	}
	.cart-popup__close-button {
		background-color: transparent;
		border: none;
		background: url("./../svg/icon-modal-close.svg") no-repeat;
		width: 46px;
		height: 46px;
		position: absolute;
		top: -26px;
		right: -26px;
	}

	.btn {
		padding: 8px 20px;
		font-size: 14px;
	}

	.cart__item {
		font-size: 14px;
	}

	.cart-img {
		width: 56px;
	}

	.cart__item-title {
		width: 210px;
		padding: 0 10px 0 15px;
	}

	.cart__item-amount {
		zoom: 0.8;
	}

	.cart__item-price {
		width: 120px;
	}

	.basket-sum {
		width: 90px;
		padding: 0 15px 0 0;
	}

	.cart__item-input {
		padding: 5px 10px 6px;
		&:focus {
			padding: 5px 10px 6px;
		}
	}
}

.basket-fix-order {
	border-radius: 0 0 4px 4px;
	font-size: 14px;
	padding: 20px 35px;
	min-height: 77px;
	background: url("./../svg/dot-2.svg") top repeat-x;
}

.basket-fix-summ {
	float: right;
	text-align: right;
	line-height: 37px;
}

.basket-fix-summ b {
	color: #e42a2a;
	font-family: "SFUIDisplay-Bold", Arial, Helvetica, sans-serif;
	font-size: 18px;
}

.news-item-page {
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: #d1d1d1 solid 1px;
	color: #000;
	display: block;
}

.news-item-page:last-child {
	border: none;
}

.news-item-page:hover,
.news-item-page:focus {
	color: #000;
}

.news-item-img {
	float: left;
	width: 150px;
}

.news-item-wrap {
	margin-left: 180px;
}

.news-date-page {
	color: #9b9b9b;
	font-size: 12px;
	margin-bottom: 5px;
}

.news-item-page {
	.news-title-page {
		display: block;
		font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
		font-size: 18px;
		margin-bottom: 10px;
		color: #000;
		text-decoration: none;
	}
	&:hover,
	&:focus {
		.news-title-page {
			color: #f55b5b;
		}
	}
}

.news-item-page .news-anons-page p {
	margin-bottom: 5px;
}

.page404 {
	background: url("./../images/page-404.png") top left no-repeat;
	padding-top: 591px;
	margin: -33px 0 0 -40px;
	padding-left: 40px;
	font-size: 13px;
	line-height: 18px;
}

.preloader__container {
	position: absolute;
	width: 100%;
	height: 100%;
}
.preloader {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	left: 50%;
	top: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		margin: 8px;
		border: 8px solid #fff;
		border-radius: 50%;
		animation: preloader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #c5bbbb transparent transparent transparent;
		&:nth-child(1) {
			animation-delay: -0.45s;
		}
		&:nth-child(2) {
			animation-delay: -0.3s;
		}
		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}
}

@keyframes preloader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.field-required {
	color: #f47166;
}

.search-form__wrap {
	.search__input-control {
		border: #dadada solid 1px;
		color: #878787;
		font-size: 15px;
		border-radius: 4px;
		background: #FFF;
		vertical-align: top;
		margin-right: 3px;
	}
}

.forget-form__wrap {
	.forget__input-control {
		border: #dadada solid 1px;
		color: #878787;
		font-size: 15px;
		border-radius: 4px;
		background: #FFF;
		vertical-align: top;
		margin-right: 3px;
	}
	.form-group {
		margin-bottom: 1rem;
	}
}

.cart-prepaid__container,
.cart-prepaid-full__container {
	label {
		p {
			margin: 0;
			display: inline;
		}
	}
}

.cart-from-manager__container {
	display: none;
}

.optioned__group-container {
	text-align: center;
	margin-top: 10px;
}

.optioned__property-title {
	float: left;
	width: 100%;
	margin-bottom: 5px;
	font-weight: bold;
}

.optioned__form_popup {
	.optioned__property-container {
		margin-bottom: 15px;
	}
}

.purch {
	h2 {
		font-size: 28px;
		line-height: 34px;
		color: #be1010;
	}
	.info-purch {
		border: #fccccb solid 2px;
		border-radius: 10px;
		padding: 10px 0 0 0;
		margin-bottom: 10px;
		.info-purch-item {
			margin-bottom: 12px;
			line-height: 28px;
			margin-left: -1px;
			.info-purch-item-title {
				display: inline-block;
				color: #ffffff;
				font-weight: bold;
				background: #f76868;
				font-family: Georgia, "Times New Roman", Times, serif;
				font-style: italic;
				position: relative;
				padding-left: 12px;
				margin: 0 20px 0 0;
				&:after {
					position: absolute;
					display: block;
					width: 0;
					height: 0;
					border-color: transparent;
					border-style: solid;
					content: "";
					border-width: 14px;
					top: 0;
					right: -14px;
					content: " ";
					border-left-color: #f76868;
					border-right-width: 0;
				}
			}
		}
	}

	.you-ordered {
		margin-bottom: 20px;
		h4 {
			font-size: 15px;
			font-weight: bold;
			margin-bottom: 10px;
			color: #282828
		}
		.you-ordered-inner {
			border: #fccccb solid 2px;
			border-radius: 10px;
		}
		.you-ordered-inner-item {
			background: #fff5d9;
			border-radius: 10px;
		}
		.you-ordered-item {
			padding: 5px;
			border-bottom: #fccccb solid 2px;
			border-radius: 10px;
			font-size: 12px;
			line-height: 16px;
			&:hover {
				color: #454545
			}
			a {
				color:#454545;
			}
			.you-ordered-img {
				display: table-cell;
				vertical-align: middle;
				width: 62px;
				margin-right: 10px;
			}
			.you-ordered-name {
				display: table-cell;
				vertical-align: middle;
				width: 300px;
			}
			img {
				width: 50px;
				border: #fccccb solid 1px;
				border-radius: 5px;
			}
			.you-ordered-price {
				line-height: 52px;
				margin-right: 20px
			}
		}
	}
	.you-ordered-sum {
		padding: 15px 10px 12px 10px;
		p {
			margin: 0 0 3px 0;
		}
	}
	.text-it {
		font-family: Georgia, "Times New Roman", Times, serif;
	}
}

.cart-prepaid-full__container,
.cart-prepaid__container {
	display: none;
}

.cart-prepaid__message {
	margin-bottom: 10px;
	font-weight: bold;
	color: #ed1c24;
	display: none;
}

.opt_prop {
	float: left;
	margin: 0 20px 10px 0;
	width: 65px;
}

.like_block {
	margin: 10px 0 0 0;
	.like_item {
		display: inline-block;
		margin: 0 3px;
	}
}

@media (min-width: 1400px) {
	.container {
		max-width: 1330px;
	}

	.logo {
		margin: 10px 100px 0 0;
	}

	.header-fb {
		margin-right: 60px;
	}

	.navbar-search {
		width: 390px;
	}

	.navbar-search .search-query {
		width: 340px;
	}

	.header-top__btn {
		margin: 4px 0 0 340px;
	}

	.nav-bottom-left__menu .nav-link {
		padding: 0 25px;
	}

	.left-block {
		width: 280px;
		margin-right: 40px;
	}

	.right-block {
		width: 980px;
	}

	.good-main__wrap {
		margin: 0 -7px;
	}

	.good-item {
		margin: 0 7px 20px 7px;
		width: 231px;
		padding-bottom: 20px;
	}

	.good-item__list-wrap {
		width: 410px;
		margin-left: 30px;
	}

	.good-item__list .good-item__price {
		margin-left: 30px;
	}

	.good-item__list .add-basket,
	.good-item__list .report {
		margin-left: 30px;
	}

	.card-other__item {
		width: 155px;
		margin: 0 3px 12px 3px;
	}

	.cat-item {
		padding: 0;
		margin-bottom: 40px;
	}

	.cat-inner-item {
		padding: 80px 25px 35px 25px;
	}

	.left-menu__wrap {
		padding: 17px 30px;
	}

	.left-news__wrap {
		padding: 25px 25px 2px 25px;
	}

	.good-sale {
		margin: 0 20px 15px 20px;
	}

	.good-item__price span {
		font-size: 14px;
	}

	.good-item__price {
		font-size: 20px;
	}

	.card-other__price {
		font-size: 15px;
	}

	.good-item__art {
		margin: 0 20px 12px 20px;
	}

	.add-basket, .report {
		margin: 0 20px;
	}

	.good-item__label div {
		margin: 0 3px 3px 3px;
		padding: 6px 12px;
	}

	.good-item__title {
		font-size: 15px;
	}

	.card-other__item .good-item__title {
		font-size: 13px;
	}

	.card-left {
		width: 300px;
	}

	.card-right {
		width: 650px;
		margin-left: 30px;
	}

	.cart-header__item-title {
		width: 368px;
	}

	.cart__item-title {
		width: 297px;
		padding: 0 50px 0 20px;
	}

	.cart-header__item-price-total {
		width: 190px;
	}

	.cart__item-price {
		width: 195px;
	}

	.basket-sum {
		width: 180px;
	}

	.basket-bottom__promo {
		width: 341px;
		margin-right: 50px;
	}

	.basket-bottom__promo .required {
		width: 220px;
	}

	.basket-bottom__delivery {
		width: 305px;
		margin-right: 50px;
	}

	.basket-bottom__sum {
		width: 235px;
	}

	.cart-header__item-amount {
		width: 323px;
	}

	.quality-banner__left-block {
		min-height: 205px;
		padding: 60px 80px 60px 75px;
	}

	.quality-banner__right-block {
		min-height: 205px;
		padding: 60px 50px 60px 120px;
	}

	.reviews-left {
		width: 620px;
	}

	.reviews-right {
		width: 340px;
		padding: 25px;
	}

	.delivery-moscow-table td {
		padding: 18px 25px;
	}

	.cart-popup-container {
		left: 50%;
		right: inherit;
	}
}

@media (min-width: 1740px) {
	.container {
		max-width: 1450px;
	}
	.left-block {
		width: 300px;
		margin-right: 60px;
	}

	.right-block {
		width: 1060px;
	}

	.good-main__wrap {
		margin: 0 -9px;
	}

	.good-item {
		margin: 0 9px 20px 9px;
		width: 248px;
	}

	.card-other__item {
		width: 160px;
		margin: 0 3px 12px 3px;
	}

	.cat-item {
		padding: 0;
		margin-bottom: 40px;
	}

	.cat-inner-item {
		padding: 80px 25px 35px 25px;
	}

	.card-left {
		width: 340px;
	}

	.card-right {
		width: 670px;
		margin-left: 50px;
	}

	.card-img__small {
		width: 75px;
	}

	.cart-header__item-title {
		width: 468px;
	}

	.cart__item-title {
		width: 397px;
		padding: 0 100px 0 20px;
	}

	.basket-bottom__delivery {
		width: 375px;
	}

	.delivery-rus-pnas .form-control {
		width: 600px;
	}

	.reviews-left {
		width: 670px;
	}

	.reviews-right {
		width: 370px;
	}

	.good-item__list-wrap {
		width: 480px;
	}

	.cart-popup-container {
		left: 55%;
		right: inherit;
	}
}

@media (max-width: 1199px) {
	h1 {
		font-size: 28px;
		margin-bottom: 15px;
	}

	h2 {
		font-size: 24px;
	}

	h3 {
		font-size: 22px;
	}

	h4 {
		font-size: 20px;
	}

	h5 {
		font-size: 18px;
	}

	h6 {
		font-size: 16px;
	}

	.wrap {
		margin: 0;
		min-height: inherit;
	}

	.push {
		height: 0;
	}

	footer {
		height: auto;
	}

	.logo {
		float: left;
		width: 200px;
		margin: 10px 30px 0 0;
	}

	.breadcrumb {
		.breadcrumb-item {
			> a,
			> span {
				padding: 0 15px;
			}
		}
	}

	.header-fb {
		float: left;
		margin: 14px 25px 16px 0;
		font-size: 14px;
	}

	.header-basket {
		float: right;
		padding: 7px 0 0 45px;
		margin: 12px 0 0 0;
		font-size: 13px;
	}

	.header-top__btn {
		margin-left: 0;
	}

	.nav-bottom-left__menu .nav-link {
		padding: 0 10px;
	}

	.navbar-search {
		width: 300px;
	}

	.navbar-search .search-query {
		width: 250px;
	}

	.right-block {
		float: left;
		width: 650px;
	}

	.main-title h3 {
		font-size: 24px;
	}

	.good-item:nth-child(4) {
		display: none;
	}

	.cat-item:nth-child(4) {
		display: inline-block;
	}

	.good-item {
		width: 207px;
	}

	.card-other__item {
		width: 120px;
		margin: 0 3px 12px 3px;
	}

	.add-basket {
		padding: 8px;
	}

	.report {
		padding: 12px 8px 9px 8px;
	}

	.nav-footer-left__menu .nav-item {
		margin: 0 20px 0 0;
	}

	.footer-center__left {
		max-width: 400px;
	}

	.sort-left {
		float: none;
	}

	.sort-reset {
		float: none;
		display: inline-block;
		margin: 15px 0 0 0;
	}

	.card-left {
		width: 240px;
	}

	.card-right {
		width: 380px;
	}

	.card-tab-content {
		padding: 15px;
	}

	.card-reviews__wrap {
		padding: 15px;
	}

	.card-add-reviews__top {
		padding: 10px 15px;
	}

	.card-add-reviews__bottom {
		padding: 10px 15px 15px 15px;
	}

	.card-add-reviews__mark {
		margin-left: 6px;
		padding-left: 8px;
	}

	.cart-top {
		padding: 17px 15px;
	}

	.cart__item {
		padding: 15px;
	}

	.cart__item-title {
		width: 170px;
		font-size: 14px;
		padding: 0 15px;
	}

	.cart__item-price {
		width: 115px;
		padding: 0 0 0 5px;
	}

	.cart__item-amount {
		width: 114px;
		.cart__item-input {
			width: 36px;
		}
	}

	.basket-sum {
		width: 130px;
	}

	.basket-bottom__promo-wrap {
		width: 420px;
	}

	.basket-bottom__delivery {
		width: inherit;
	}

	.delivery-options .radio {
		padding: 15px 15px 15px 70px;
	}

	.order-contacts-form {
		padding: 15px 25px;
	}

	.cart-header__item-title {
		width: 238px;
	}

	.cart-header__item-amount {
		width: 234px;
	}

	.delivery-moscow-table td {
		padding: 8px;
		font-size: 12px;
	}

	.russian-delivery-destination {
		display: block;
		width: 100%;
		margin-bottom: 7px;
	}

	.delivery-rus-pnas .form-control {
		margin-left: 0;
	}

	.reviews-left {
		width: inherit;
		float: none;
		margin: 0 0 20px 0;
	}

	.reviews-right {
		width: inherit;
		float: none;
		padding: 15px;
	}

	.good-item__list .add-basket,
	.good-item__list .report {
		width: 120px;
		font-size: 13px;
	}

	.good-item__list-wrap {
		width: 240px;
	}

	.good-item__tab .not-in-stock::after {
		opacity: 0.3;
	}
}

@media (max-width: 991px) {
	body {
		font-size: 16px;
	}

	.hide__desktop {
		display: none;
	}

	.hide__mobile {
		display: block;
	}

	.call__container {
		float: left;
		&.active {
			.call__container_content {
				display: block;
			}
		}
		.call__container_icon {
			width: 70px;
			height: 70px;
			background: #88c329;
			cursor: pointer;
			position: relative;
			&:after {
				content: "";
				top: 22px;
				left: 22px;
				position: absolute;
				display: block;
				width: 26px;
				height: 26px;
				background: url("./../svg/icon-call.svg") 0 no-repeat;
			}
		}
		.call__container_content {
			display: none;
			position: absolute;
			top: 70px;
			left: 0;
			right: 0;
			background: #f1f7f9;
			z-index: 200;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
			text-align: center;
			padding: 15px;
		}
	}


	.header-phone,
	.header-hours,
	.header-call {
		a,
		label {
			margin: 0 0 10px 0;
		}
	}

	.call-order .call-order-small {
		text-align: center;
		color: #b04444;
		font-size: 17px;
		line-height: 23px;
		font-weight: bold;
	}

	.work-now {
		margin: 10px 0;
	}

	.call-order .co-2,
	.call-order .co-2:hover,
	.call-order .co-2:focus,
	.call-order .co-1,
	.call-order .co-1:hover,
	.call-order .co-1:focus {
		font-size: 16px;
		line-height: 20px;
		width: inherit;
		height: inherit;
		margin: 3px 10px 10px 10px;
		border: #88c329 solid 1px;
		padding: 2px 5px;
		border-radius: 3px;
		color: #88c329;
		display: inline-block;
		background: #fff;
	}

	.call-order .co-2,
	.call-order .co-2:hover,
	.call-order .co-2:focus {
		margin-bottom: 0;
	}

	.call-order .block-mob {
		font-weight: normal;
		font-size: 14px;
		line-height: 18px;
		margin-bottom: 10px;
		color: #000;
	}

	.navbar__mobile_logo {
		margin: 0 auto;
		text-align: center;
		display: block;
		max-width: 262px;
		padding: 7px 30px 0 0;
	}

	.navbar__mobile_top {
		.sign-reg {
			float: right;
			margin: 5px 15px 0 0;
			text-align: center;
			span,
			i {
				display: none;
			}
			a {
				display: block;
				text-align: center;
				text-decoration: none;
				margin: 7px 0;
				font-size: 14px;
				font-family: "SFUIDisplay-Semibold", Arial, Helvetica, sans-serif;
				line-height: 18px;
				color: #000;
			}
		}
	}

	.navbar__mobile_bottom {
		background: #f55b5b;
		height: 60px;
		color: #fff;
		font-size: 15px;
		line-height: 15px;
		position: relative;
	}

	.additional-menu .navbar__mobile_bottom {
		-webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
		box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
	}

	.cart__mobile_container {
		float: left;
		width: 20%;
		background: #977eac;
		height: 60px;
		text-decoration: none;
		color: #fff;
		font-size: 13px;
		text-align: center;
		&:hover,
		&:focus {
			color: #fff;
		}
	}

	.cart__mobile_icon {
		width: 30px;
		height: 28px;
		margin: 9px auto 2px auto;
		position: relative;
		background: url("./../svg/icon-cart-mobile.svg") no-repeat;
		span {
			position: absolute;
			min-width: 21px;
			height: 21px;
			border-radius: 50%;
			background: #f55b5b;
			text-align: center;
			color: #fff;
			font-size: 12px;
			line-height: 21px;
			padding: 0 2px;
			top: -5px;
			right: -10px;
			-webkit-box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.13);
			-moz-box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.13);
			box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.13);
		}
	}

	.mobile__menu_block {
		float: left;
		width: 20%;
		text-align: center;
		cursor: pointer;
		height: 60px;
		padding-top: 17px;
	}

	.mobile__menu_icon {
		display: inline-block;
		width: 26px;
		height: 26px;
		vertical-align: middle;
		margin-right: 7px;
		background-repeat: no-repeat;
		&.main__menu_icon {
			background-image: url("./../svg/icon-main-menu.svg");
		}
		&.catalog__menu_icon {
			background-image: url("./../svg/icon-catalog-menu.svg");
		}
		&.search__menu_icon {
			background-image: url("./../svg/icon-search-menu.svg");
		}
		&.important__menu_icon {
			background-image: url("./../svg/icon-important-menu.svg");
		}
	}

	.nav-mob {
		display: none;
		.navbar-nav {
			margin: 10px 0;
			> li {
				background: none;
				margin: 10px 0;
				> a {
					width: auto;
					background: none;
					padding: 0;
					margin: 0;
					color: #fff;
					text-transform: none;
					text-decoration: none;
					text-shadow: 1px 1px 1px #a1ba46;
					border-bottom: #fff dashed 1px;
					display: inline;
					font-size: 22px;
				}
			}
		}
		.active & {
			display: block;
			position: absolute;
			top: 60px;
			z-index: 300;
			background: #88c329;
			left: 0;
			right: 0;
			text-align: center;
			-webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
			-moz-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
			box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
		}
	}

	.nav__mobile_catalog {
		display: none;
		.left-menu__wrap {
			margin: 0;
		}

		.active & {
			display: block;
			position: absolute;
			top: 60px;
			z-index: 200;
			left: 0;
			right: 0;
			text-align: left;
		}
	}

	.nav-mob-wrap {
		border: #ffffff solid 1px;
		border-radius: 7px;
		margin: 10px;
	}

	.navbar-mob-search {
		display: none;
		.navbar-search {
			float: none;
			margin: 0 auto;
		}
		.active & {
			display: block;
			position: absolute;
			top: 60px;
			z-index: 200;
			background: #fff url("./../images/bottom-bg-1.jpg") center no-repeat;
			left: 0;
			right: 0;
			padding: 15px 0;
			-webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
			-moz-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
			box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
		}
	}

	.navbar-mob-imp {
		display: none;
		.active & {
			display: block;
			position: absolute;
			top: 60px;
			z-index: 1;
			background: #88c329;
			left: 0;
			right: 0;
			-webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
			-moz-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
			box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
		}
	}

	.navbar-mob-search {
		display: none;
	}

	.left-block {
		display: none;
	}

	.right-block {
		float: none;
		width: inherit;
	}

	.owl-carousel.owl-main-slider {
		display: none !important;
	}

	.good-main {
		margin-top: 15px;
	}

	.good-item {
		width: 220px;
	}

	.card-other__item {
		width: 160px;
		margin: 0 3px 12px 3px;
	}

	.main-title {
		margin-bottom: 10px;
	}

	.main-title h3 {
		font-size: 20px;
	}

	.nav-footer-left__menu {
		display: none;
	}

	footer {
		text-align: center;
	}

	footer .navbar-search {
		margin: 0 auto;
		float: none;
	}

	.footer-center {
		padding: 15px 0 0 0;
	}

	.footer-bottom {
		border-top: none;
		padding: 0 0 5px 0;
	}

	.footer-center__left,
	.footer-center__center,
	.footer-center__right,
	.footer-bottom__left,
	.footer-bottom__center,
	.footer-bottom__right {
		float: none;
		max-width: inherit;
		margin: 0 0 10px 0;
	}

	.text-main {
		display: none;
	}

	.additional-menu {
		position: fixed;
		margin: 0 auto;
		z-index: 1094;
		top: 0;
		left: 0;
		right: 0;
		display: none;
	}

	.breadcrumb {
		margin: 15px 0;
		line-height: 30px;
		.breadcrumb-item {
			> a,
			> span {
				padding: 0 15px;
			}
			&:first-child {
				a {
					width: 30px;
					height: 30px;
					&:after {
						top: 7px;
						left: 7px;
					}
				}
				&:after {
					height: 30px;
				}
			}
			+.breadcrumb-item {
				&:after {
					height: 30px;
					background-position: 0 -8px;
				}
			}
		}
	}

	.cat-item__title {
		padding: 15px 10px 10px 10px;
		font-size: 16px;
	}

	.cat-item {
		margin-bottom: 20px;
	}

	.cat-block {
		margin-bottom: 0;
		position: relative;
		border-bottom: none;
		padding-bottom: 0;
	}

	.cat-block:before, .cat-block:after {
		display: none;
	}

	.cat-inner-item {
		padding: 50px 10px 25px 10px;
	}

	.cat-inner-item__title {
		padding: 10px 10px 0 10px;
		font-size: 14px;
	}

	.sort-s1 {
		margin-bottom: 10px;
	}
	.card-right {
		width: 420px;
	}

	.card-other__item {
		width: 133px;
	}

	.header_displaced {
		margin-top: 20px;
	}

	.basket-btn-top {
		margin-top: -60px;
	}

	.cart__item-title {
		width: 210px;
	}

	.cart-header__item-title {
		width: 274px;
	}

	.basket-bottom__promo-wrap {
		width: 460px;
	}

	.basket-bottom__delivery {
		padding-right: 30px;
	}

	.card-img__small {
		width: 50px;
	}

	.reg-wrap {
		padding: 35px;
		max-width: 450px;
		margin-bottom: 15px;
	}

	.faq {
		padding: 15px;
	}

	.quality-banner-top {
		font-size: 15px;
	}

	.quality-banner__left-block {
		min-height: 142px;
		padding: 15px 45px 15px 15px;
	}

	.quality-banner__right-block {
		min-height: 142px;
		padding: 15px 15px 15px 45px;
	}

	.tester-block-left {
		margin-bottom: 20px;
	}

	.tester-block-inner {
		margin-bottom: 15px;
	}

	.tester-block {
		margin-bottom: 15px;
	}

	.delivery-moscow-table td {
		padding: 5px;
	}

	.delivery-moscow-header,
	.delivery-rus-header {
		padding: 10px 10px 10px 60px;
		font-size: 16px;
	}

	.delivery-moscow-header::before,
	.delivery-rus-header::before {
		left: 10px;
		zoom: 0.7;
	}

	.delivery-rus-pnas .form-control {
		width: 300px;
		padding: 8px 20px;
	}

	.pay-block {
		padding: 15px;
	}

	.pay-block__title {
		padding: 10px 15px;
	}

	.pay-block__item {
		padding: 15px 15px 5px 15px;
	}

	.pay-block__item-title {
		position: relative;
		display: inline-block;
		left: -15px;
		top: 0;
		margin-bottom: 15px;
	}

	.comparison-title {
		padding: 15px;
		max-width: 180px;
	}

	.comparison-item {
		padding: 15px 15px 5px 180px;
	}

	.quality-banner-top {
		margin-bottom: 15px;
	}

	.cont {
		padding: 15px;
		margin-bottom: 20px;
	}

	.cont-title {
		font-size: 16px;
	}

	.cont-map {
		margin: 20px 0;
	}

	.cont-form {
		padding: 15px;
	}

	.cont-form .cont-title {
		margin: 0 0 20px 0;
	}

	.good-item__list-wrap {
		width: 280px;
	}

	.news-item-page {
		padding-bottom: 20px;
		margin-bottom: 20px;
	}

	.news-item-img {
		width: 100px;
	}

	.news-item-wrap {
		margin-left: 120px;
	}
}

@media (max-width: 768px) {
	body {
		font-size: 14px;
	}

	h1 {
		font-size: 22px;
		margin-bottom: 15px;
	}

	h2 {
		font-size: 20px;
	}

	h3 {
		font-size: 18px;
	}

	h4 {
		font-size: 16px;
	}

	h5 {
		font-size: 14px;
	}

	h6 {
		font-size: 12px;
	}

	.btn {
		padding: 8px 20px;
	}

	.breadcrumb {
		font-size: 12px;
		.breadcrumb-item {
			> a,
			> span {
				padding: 0 0 0 5px;
			}
		}
	}

	.good-item {
		width: 248px;
	}

	.card-other__item {
		width: 160px;
		margin: 0 3px 12px 3px;
	}

	.good-item:nth-child(4) {
		display: inline-block;
	}

	.sort {
		font-size: 14px;
	}

	.card-left {
		width: 290px;
		float: none;
		margin: 0 auto;
	}

	.card-img__small {
		width: 62px;
	}

	.card-right {
		width: inherit;
		float: none;
		margin: 25px 0 0 0;
	}

	.card-other__wrap {
		text-align: center;
	}

	.basket-btn-top {
		margin-top: -50px;
	}

	.cart__item-title {
		width: 150px;
		padding: 0 10px;
	}

	.cart__item-amount {
		width: 121px;
		display: block;
		margin: 0 0 5px 0;
		.cart__item-amount-control {
			border-radius: 4px;
			margin: 0;
		}
		.cart__item-input {
			width: 47px;
			border-top: #dadada solid 1px;
			border-bottom: #dadada solid 1px;
			border-left: none;
			border-right:none;
		}
	}

	.cart__item-cross {
		margin-right: 5px;
	}

	.cart__item-price {
		display: inline-block;
		width: 90px;
		font-size: 14px;
		padding: 0 0 0 10px;
	}

	.basket-sum {
		font-size: 14px;
		width: 100px;
		padding: 0 10px;
	}

	.cart-header__item-title {
		width: 214px;
	}

	.cart-header__item-amount {
		width: 140px;
	}

	.basket-bottom__promo-wrap {
		width: 291px;
	}

	.basket-bottom__promo .required {
		font-size: 11px;
		width: 170px;
		padding: 11px 15px;
	}

	.basket-bottom__promo {
		width: 291px;
	}

	.basket-bottom__sum {
		width: inherit;
		font-size: 15px;
		padding-left: 10px;
		display: block;
	}

	.basket-bottom__promo-text {
		font-size: 14px;
	}

	.delivery-title,
	.order-contacts-title {
		padding: 11px 55px;
		&:before {
			width: 42px;
			height: 42px;
		}
		&:after {
			left: 6px;
			top: 6px;
		}
	}

	.delivery-options .radio label {
		font-size: 15px;
		margin-bottom: 5px;
	}

	.delivery-text {
		font-size: 14px;
	}

	.cart__item-title {
		width: inherit;
		max-width: 200px;
		padding: 0 10px;
		font-size: 13px;
	}

	.basket-btn-top {
		float: none;
		margin: 0 0 20px 0;
	}

	.cart-title__wrap {
		display: block;
		margin: 0 0 5px 0;
	}

	.basket-sum,
	.cart__item-remove-container {
		display: inline-block;
	}

	.cart-top {
		display: none;
	}

	.cart__item {
		display: block;
	}

	.basket-bottom__promo-wrap {
		width: inherit;
		display: block;
		margin: 0;
	}

	.why-cheap::before {
		top: 50%;
		margin-top: -15px;
	}

	.basket-bottom__delivery {
		padding-right: 0;
		margin: 0 0 15px 0;
		font-size: 14px;
	}

	.delivery-options .radio {
		padding: 15px 15px 15px 60px;
	}

	.delivery-options .radio label:after {
		left: -45px;
	}

	.reg-wrap {
		padding: 15px;
	}

	.delivery-rus-pnas .form-control {
		width: 170px;
	}

	.card-reviews__wrap-all {
		margin-bottom: 20px;
		padding: 15px;
	}

	.card-reviews__adm {
		padding: 15px;
	}

	.discounts-title {
		font-size: 16px;
		margin-bottom: 15px;
	}

	.discounts-item {
		padding: 15px 15px 5px 15px;
	}

	.comparison-title {
		padding: 0;
		margin-bottom: 15px;
		font-size: 16px;
		max-width: inherit;
		position: relative;
	}

	.comparison-item {
		padding: 15px 15px 5px 15px;
	}

	.comparison-top {
		padding: 15px;
	}

	.cart-popup-container {
		display: none !important;
	}

	.good-item__list-img {
		width: 80px;
	}

	.good-item__list-wrap {
		width: inherit;
		float: none;
		margin: 0 0 0 95px;
		display: block;
		text-align: left;
	}

	.good-item__tab .good-item__list-wrap {
		margin: 0;
	}

	.good-item__tab .good-item__title {
		margin: 0;
	}

	.good-item__list .good-item__price {
		margin: 20px 0 0 0;
	}

	.good-item__list .add-basket,
	.good-item__list .report {
		width: 175px;
		margin: 10px 0 0 15px;
	}

	.good-item__tab .not-in-stock::after {
		right: 40%;
	}

	.news-item-img {
		width: 80px;
	}

	.news-item-wrap {
		margin-left: 95px;
	}
}

@media (max-width: 576px) {
	#toTop {
		display: none !important;
	}

	.breadcrumb {
		font-size: 11px;
		.breadcrumb-item {
			a,
			span {
				padding: 0 0 0 3px;
			}
			+ .breadcrumb-item {
				&:after {
					width: 10px;
					background-position: -5px -8px;
				}
			}
		}
	}

	.navbar__mobile_logo {
		max-width: 190px;
		padding-right: 35px;
	}

	.good-main__wrap {
		text-align: center;
	}

	.good-item {
		width: 47%;
	}

	.card-other__item {
		width: 47%;
	}

	.main-title:before,
	.main-title:after,
	.main-title__wrap:before,
	.main-title__wrap:after {
		display: none;
	}

	.main-title h3 {
		font-size: 18px;
	}

	.call__container {
		.call__container_icon {
			width: 55px;
			height: 55px;
			&:after {
				top: 14px;
				left: 14px;
			}
		}
	}

	.navbar__mobile_top .sign-reg {
		float: right;
		padding: 6px 10px 6px 10px;
		text-align: left;
		border-left: #e8e8e8 solid 1px;
		margin: 0;
	}

	.navbar__mobile_top .sign-reg a {
		font-size: 13px;
		font-family: "SFUIDisplay-Medium", Arial, Helvetica, sans-serif;
		margin: 2px 0;
		color: #5c5c5c;
		text-align: left;
	}

	.navbar__mobile_bottom {
		height: 50px;
		font-size: 12px;
	}

	.mobile__menu_icon {
		display: block;
		margin: 0 auto 2px auto;
	}

	.mobile__menu_block {
		height: 50px;
		padding-top: 4px;
	}

	.cart__mobile_container {
		&:hover,
		&:focus,
		& {
			height: 50px;
		}
	}

	.cart__mobile_icon {
		margin: 4px auto 1px auto;
		span {
			top: -1px;
		}
	}

	.active {
		.nav-mob,
		.nav__mobile_catalog,
		.navbar-mob-search,
		.navbar-mob-imp {
			top: 50px;
		}
	}

	.call__container {
		.call__container_content {
			top: 55px;
		}
	}

	.cat-item {
		width: 46%;
	}

	.sort-s1 {
		display: block;
		margin: 0 0 10px 0;
	}

	.sort-s1 a {
		font-size: 13px;
		margin: 5px 10px;
	}

	.sort-s1 .active {
		padding: 5px 10px;
	}

	.sort-s2 {
		margin-right: 10px;
	}

	.sort-type {
		margin-bottom: 15px;
		a,
		span {
			width: 50%;
			font-size: 14px;
			padding: 10px 5px;
			text-align: center;
		}
	}

	.more-large a {
		padding: 10px 15px;
	}

	.sort-brand__list {
		float: none;
		width: inherit;
	}

	.sort-brand {
		margin-bottom: 15px;
	}

	.card-tabs .nav-link {
		padding: 7px 10px;
	}

	.quality-banner-top {
		font-size: 12px;
		margin: 0 -15px 20px -15px;
	}

	.quality-banner__left-block {
		min-height: 142px;
		padding: 10px 45px 10px 10px;
	}

	.quality-banner__right-block {
		min-height: 142px;
		padding: 10px 10px 10px 45px;
	}

	.tester-block-left {
		margin-right: 0;
	}

	.delivery-rus-pnas .form-control {
		width: 100%;
		margin-bottom: 10px;
		border-right: #dadada solid 1px;
		border-radius: 4px;
	}

	.delivery-rus-pnas .btn {
		margin-left: 0;
		border-radius: 4px;
	}
}

@media (max-width: 410px) {
	.breadcrumb {
		font-size: 10px;
	}

	.good-item {
		width: 290px;
	}

	footer .navbar-search {
		margin: 0 -15px;
	}

	.cat-item {
		width: 46%;
	}

	.cat-item__title {
		padding: 12px 8px 8px 8px;
		font-size: 14px;
	}

	.sort {
		font-size: 13px;
	}

	.sort-s1 .active:after {
		margin-left: 5px;
	}

	.delivery-title,
	.order-contacts-title {
		padding: 3px 55px;
		font-size: 14px;
		min-height: 42px;
	}

	.tester-block-all {
		width: 260px;
	}

	.img-left {
		float: none;
		margin: 0 0 10px 0;
	}

	.img-right {
		float: none;
		margin: 0 0 10px 0;
	}

	.good-item__list {
		padding: 10px;
	}

	.good-item__list .add-basket,
	.good-item__list .report {
		width: 135px;
		margin: 10px 0 0 10px;
		font-size: 12px;
	}
}

.fancybox-lock body {
	overflow: hidden !important;
}
