@charset "utf-8";

.ui-widget-content {
	border-radius: 0 0 4px 4px;
	border: #ffd5d1 solid 1px;
	border-top: none;
	width: 340px;
	a {
		display: block;
		padding: 3px;
		text-decoration: none;
	}

	.image {
		float: left;
		width: 60px;
		margin-right: 15px;
	}

	.autocomplete-item-text {
		display: block;
		min-height: 60px;
		margin-left: 75px;
	}

	.ui-menu-item-price {
		font-size: 11px;
	}

	.ui-menu-item-price strong {
		font-size: 14px;
		font-weight: bold;
		color: #f76868;
	}

	h6 {
		margin: 0;
		font-family: Verdana, Geneva, sans-serif;
		font-size: 12px;
		line-height: 18px;
	}

	.ui-state-hover {
		border: none;
		border-radius: 0;
		background: #fff7f5;
		background-image: transparent;
	}
}

.ui-menu .ui-menu-item {
	padding: 2px;
	border-top: #ffd5d1 solid 1px;
}

.autocomplete-scrollable {
	overflow-y: auto;
	max-height: 480px;
}

.ui-autocomplete {
	z-index: 200 !important;
}
